import nav_default_logo from './nav-default-logo.png';
import main_logo from './main-logo.svg';
import swipe_up from './swipe-up.svg';
import arrow_down from './arrow-down.svg';
import arrow_line from './arrow-line.svg';
import carousel_arrow from './carousel-arrow.svg'
import insta_logo from './insta-logo.png'
import jek_logo from './jek-logo.svg'
import linkedin_logo from './linkedin-logo.png'
import nei_logo from './nei-logo.png'
import tiktok_logo from './tiktok-logo.png'
import twitter_logo from './twitter-logo.png'
import shift_trailer from './shift-trailer.mp4'
import nav_about_logo from './nav-about-logo.png'
import nav_calendar_logo from './nav-calendar-logo.png'
import nav_partners_logo from './nav-partners-logo.png'
import nav_carousel_logo from './nav-carousel-logo.png'
import register_home from './register-home-img.png'
import forms_swipe from './forms-swipe.svg'
import forms_tick from './tick.svg'
import loading_page_image_1 from './loading-page-image-1.png'
import loading_page_image_2 from './loading-page-image-2.png'
import shift_academy_logo from './shift-academy-logo.svg'
import acc_logo from './partners/acc-logo.png'
import goodbarber_logo from './partners/goodbarber-logo.png'
import redlight_logo from './partners/redlight-logo.png'
import singlestore_logo from './partners/singlestore-logo.png'
import void_logo from './partners/void-logo.png'
import zona_pro_logo from './partners/zona-pro-logo.png'
import taikai_logo from './partners/taikai-logo.png'
import fctuc_logo from './partners/fctuc-logo.png'
import cmc_logo from './partners/cmc-logo.svg'
import first_foundation_logo from './partners/first-foundation-logo.svg'
import uc_logo from './partners/uc-logo.svg'
import framedrop_logo from './partners/framedrop-logo.svg'
import outsystems_logo from './partners/outsystems-logo.svg'
import flag_logo from './partners/flag-logo.svg'
import critical_logo from './partners/critical-logo.png'
import rumos_logo from './partners/rumos-logo.png'
import nei_main_logo from './nei-main-logo.png'
import jek_main_logo from './jek-main-logo.png'
import faq_star_icon from './faq-page-star.svg'
import about_star_icon from './about-page-star.svg'
import eu_logo from './partners/eu-logo.png'
import pt_logo from './partners/pt-logo.png'
import prr_logo from './partners/prr-logo.png'
import faq_page_ball from './faq-page-ball.svg'
import faq_page_nav_icon from './faq-page-nav-icon.png'
import multicoffee_logo from './partners/multicoffee-logo.png'
import paladin_logo from './partners/paladin-logo.png'
import red_bull_logo from './partners/red-bull-logo.png'
import frusel_logo from './partners/frusel-logo.png'
import smartex_logo from './partners/smartex-logo.png'





export default  {
    nav_default_logo,
    main_logo,
    swipe_up,
    arrow_down,
    arrow_line,
    carousel_arrow,
    insta_logo,
    jek_logo,
    linkedin_logo,
    nei_logo,
    tiktok_logo,
    twitter_logo,
    shift_trailer,
    nav_about_logo,
    nav_calendar_logo,
    nav_partners_logo,
    nav_carousel_logo,
    register_home,
    forms_swipe,
    forms_tick,
    loading_page_image_1,
    loading_page_image_2,
    shift_academy_logo,
    acc_logo,
    goodbarber_logo,
    redlight_logo,
    singlestore_logo,
    void_logo,
    zona_pro_logo,
    taikai_logo,
    fctuc_logo,
    cmc_logo,
    first_foundation_logo,
    uc_logo,
    outsystems_logo,
    flag_logo,
    framedrop_logo,
    critical_logo,
    rumos_logo,
    nei_main_logo,
    jek_main_logo,
    faq_star_icon,
    about_star_icon,
    eu_logo,
    pt_logo,
    prr_logo,
    faq_page_ball,
    faq_page_nav_icon,
    multicoffee_logo,
    paladin_logo,
    red_bull_logo,
    frusel_logo,
    smartex_logo
}