import  { useState, useEffect} from 'react'
import "./Navbar.css"
import assets from "../../assets/index"
import { useNavigate } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';
import { GiHamburgerMenu } from 'react-icons/gi';
import { RxCross, RxCross1 } from 'react-icons/rx';
import { useLocation } from 'react-router-dom';

const Navbar = (props) => {

    const location = useLocation();

    const [isHovered, setIsHovered] = useState(false);
    const navigate = useNavigate();
    const [backgroundColor, setBackgroundColor] = useState('');

    const [selectedPage, setSelectedPage] = useState(location.pathname);

    const [textColor, setTextColor] = useState('');
    const [buttonColor, setButtonColor] = useState('');
        // Set the background color based on the current section

    
    

    useEffect(() => {
        if (props.NavDefaultColorView) {
            setTextColor('#E3314E');
            setBackgroundColor('#F9D6DC');
            setButtonColor('#972134');
        }
        else if (props.navbarType === "faq") {
            setTextColor("#4A73B8");
            setButtonColor("#314D7B");
            setBackgroundColor("#DBE3F1");
            
        }
        else {
            // Set the background color based on the active section
            if (props.NavAboutColorView) {
                setTextColor('#F7C94B');
                setBackgroundColor('#FDF4DB');
                setButtonColor('#A47B09');
            } else if (props.NavScheduleColorView) {
                setTextColor('#EB6F29');
                setBackgroundColor('#FBE2D4');
                setButtonColor('#9D4A1B');
            } else if (props.NavCarouselColorView) {
                setTextColor('#EF80A2');
                setBackgroundColor('#FCE6EC');
                setButtonColor('#9F556C');
            } else if (props.NavPartnersColorView || props.footerInView) {
                setTextColor('#4BB274');
                setBackgroundColor('#DBF0E3');
                setButtonColor('#32774D');
            } else {
                setTextColor('#E3314E');
                setBackgroundColor('#F9D6DC');
                setButtonColor('#972134');
            }
        }
        }, [props.NavAboutColorView, props.NavScheduleColorView, props.NavCarouselColorView, props.NavPartnersColorView, props.NavDefaultColorView]);
    

    const openRegister = () => {
        navigate('/register');
    }

    const goToEvent = () => {
        navigate('/');
    }

    const navbarStyle = {
      backgroundColor: backgroundColor,
    };

    const buttonStyle = {
        border: `2px solid ${buttonColor}`,
        backgroundColor: isHovered ? backgroundColor: buttonColor,
        color: isHovered ? buttonColor : '#ffffff',
    };

    const textStyle = {
        color: buttonColor,
    }

    const navText = {
        "PT": {
            "inscricao": "Inscreve-te!",
            "evento": "Evento",
            "sobre": "Sobre",
            "regulamento":"Regulamento"
        },
        "EN": {
            "inscricao": "Register",
            "evento": "Event",
            "sobre": "About",
            "regulamento":"Regulation"

        }
    };

    

    return (
        <div className={props.openedMenu ? 'opened-menu-navbar' : 'navbar'} style={navbarStyle}>
            <div className={props.openedMenu ? 'opened-menu-container' : 'navbar-container'}>
                {props.isMobile ? (
                    <div className="opened-navbar-header-container">
                        <div className="navbar-img-container">
                            {props.navbarType === "faq" ? (
                                <img onClick={()=> goToEvent()} src={assets.faq_page_nav_icon} className="navbar-logo" alt="nav-logo"></img>
                            ) : props.NavDefaultColorView ? (
                                <img onClick={()=> goToEvent()} src={assets.nav_default_logo} className="navbar-logo" alt="nav-logo"></img>
                            ): props.NavAboutColorView ? (
                                <img onClick={()=> goToEvent()} src={assets.nav_about_logo} className="navbar-logo" alt="nav-logo"></img>
                            ) : props.NavScheduleColorView ? (
                                <img onClick={()=> goToEvent()} src={assets.nav_calendar_logo} className="navbar-logo" alt="nav-logo"></img>
                            ) : props.NavCarouselColorView ? (
                                <img onClick={()=> goToEvent()} src={assets.nav_carousel_logo} className="navbar-logo" alt="nav-logo"></img>
                            ) : props.NavPartnersColorView || props.footerInView ? (
                                <img onClick={()=> goToEvent()} src={assets.nav_partners_logo} className="navbar-logo" alt="nav-logo"></img>
                            ) : (
                                <img onClick={()=> goToEvent()} src={assets.nav_default_logo} className="navbar-logo" alt="nav-logo"></img>
                            )}
                            
                        </div>
                        {!props.openedMenu ? (
                            
                            <GiHamburgerMenu style={{color:textColor , height:"40%",width:"6%", cursor:"pointer"}} onClick={props.toggleMenu}/>
                        ) : <RxCross1  style={{color:textColor , height:"40%",width:"6%", cursor:"pointer"}} onClick={props.toggleMenu}/> }
                       
                    </div>
                ) : 
                <div className="navbar-img-container">
                    {props.navbarType === "faq" ? (
                        <img onClick={()=> goToEvent()} src={assets.faq_page_nav_icon} className="navbar-logo"></img>
                    ) :
                    props.NavDefaultColorView ? (
                        <img onClick={()=> goToEvent()} src={assets.nav_default_logo} className="navbar-logo"></img>
                    ): props.NavAboutColorView ? (
                        <img onClick={()=> goToEvent()} src={assets.nav_about_logo} className="navbar-logo"></img>
                    ) : props.NavScheduleColorView ? (
                        <img onClick={()=> goToEvent()} src={assets.nav_calendar_logo} className="navbar-logo"></img>
                    ) : props.NavCarouselColorView ? (
                        <img onClick={()=> goToEvent()} src={assets.nav_carousel_logo} className="navbar-logo"></img>
                    ) : props.NavPartnersColorView || props.footerInView ? (
                        <img onClick={()=> goToEvent()} src={assets.nav_partners_logo} className="navbar-logo"></img>
                    ) : (
                        <img onClick={()=> goToEvent()} src={assets.nav_default_logo} className="navbar-logo"></img>
                    )}
                </div>}
                {!props.isMobile ? ( 
                <>
                    <li><a onClick={() => setSelectedPage("/")} href="/" className={`${selectedPage=== "/" ? 'navbar-selected-page' : '' } navbar-text`} style={{color:  buttonColor}} >{navText[props.currentLanguage]["evento"]}</a></li>
                    <li><a onClick={() => setSelectedPage("/sobre")} href="/sobre" className={`${selectedPage=== "/sobre" ? 'navbar-selected-page' : '' } navbar-text`}  style={{color: buttonColor}}>{navText[props.currentLanguage]["sobre"]}</a></li>
                    <li><a onClick={() => setSelectedPage("/faq")} href="/faq" className={`${selectedPage=== "/faq" ? 'navbar-selected-page' : '' } navbar-text`}  style={{color: buttonColor}}>FAQ</a></li>
                    <div className="navbar-btn-container">
                        {/*<button className="navbar-button button-text" style={{...buttonStyle }} 
                                onMouseEnter={() => setIsHovered(true)}
                                onMouseLeave={() => setIsHovered(false)} 
                                onClick={openRegister}
                >{navText[props.currentLanguage]["inscricao"]}</button>*/}
                        <a href="https://drive.google.com/file/d/17y4BWp-dQL4PulMRV2ryLqAciNpgTTL4/view" target="_blank" className="navbar-button button-text" style={{...buttonStyle }}>{navText[props.currentLanguage]["regulamento"]}</a>

                    </div>
                    </>
                ) : props.isMobile && props.openedMenu ? (
                    <div className="navbar-opened-menu-items">
                        <li><a href="/" className="navbar-text" style={{color: buttonColor, textDecoration: selectedPage === "/" ? "underline" : ""}}>{navText[props.currentLanguage]["evento"]}</a></li>
                        <li><a href="/sobre" className="navbar-text" style={{color: buttonColor, textDecoration: selectedPage === "/sobre" ? "underline" : ""}}>{navText[props.currentLanguage]["sobre"]}</a></li>
                        <li><a href="/faq" className={` navbar-text`} style={{color: buttonColor, textDecoration: selectedPage === "/faq" ? "underline" : ""}} >FAQ</a></li>
                        {/*<li><a href="/faq" className="navbar-text" style={{color: selectedPage === "/faq" ? buttonColor : textColor, textDecoration: selectedPage === "/faq" ? "underline" : ""}}>FAQ</a></li>*/}
                        <div className="navbar-btn-container">
                            <button className="navbar-button button-text" style={{...buttonStyle }} 
                                    onMouseEnter={() => setIsHovered(true)}
                                    onMouseLeave={() => setIsHovered(false)} 
                                    onClick={openRegister}
                            >{navText[props.currentLanguage]["inscricao"]}</button>
                        </div>
                    </div>
                ) : null
                }
                
            </div>
        </div>
    )
}

export default Navbar