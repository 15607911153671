import { useState, useEffect } from 'react'
import { MdMailOutline } from "react-icons/md";
import { useMediaQuery } from 'react-responsive';
import "./PartnersMail.css"



const PartnersMails = (props) => {

    const isMobile = useMediaQuery({ query: '(max-width: 1050px)' })
    const [expanded, setExpanded] = useState(false)
    const email="parcerias@shiftappens.com"
    const gmailLink = "https://mail.google.com/mail/?view=cm&fs=1&to=${encodeURIComponent(email)}"

    

    const text = {
        "PT": {
            "contact": "CONTACTO PARA PARCEIROS",
        },
        "EN": {
            "contact": "CONTACT FOR PARTNERS",
        }
    }

    const expandPartnersButton = () => {
       
        if (expanded) {
          // If already expanded, go to the Gmail link
        window.open(gmailLink, "_blank");
        } else {
          // If not expanded, toggle the expanded state
        setExpanded(!expanded);
        }
    };
    
    const handleClickOutside = (e) => {
    
    // Check if the click occurred outside of the <a> tag
    if (e.target.tagName !== 'A' && e.target.tagName !== 'svg') {
        setExpanded(false);
    }
    };

    useEffect(() => {
        // Add event listener to handle clicks outside of the <a> tag
        document.addEventListener('click', handleClickOutside);
        return () => {
          // Remove event listener when component unmounts
        document.removeEventListener('click', handleClickOutside);
        };
    }, []); 


    
    return (
        <>
        {!isMobile ? (
            <div className={` ${!props.partnersInView ? 'partners-contact-container' : 'partners-contact-container-fixed'}`}>
                {(!props.partnersInView && !props.footerInView) && (
                <>
                    <p>{text[props.currentLanguage].contact}</p>
                    <a  href={gmailLink} target="_blank" rel="noreferrer"> <span><MdMailOutline/></span> parcerias@shiftappens.com</a>             
                </>
                ) }
                {(props.footerInView || props.partnersInView) && (
                    <>
                        <a  href={gmailLink} target="_blank" rel="noreferrer"> <span><MdMailOutline/></span> parcerias@shiftappens.com</a>
                    </>
                )}
            </div>
        ) : (
            
            <div className={` ${!props.partnersInView && !props.footerInView ? 'partners-contact-mobile-extense-container' : 'partners-contact-mobile-container'}`}>
                {(!props.partnersInView && !props.footerInView) && (
                    // Render this content if both conditions are true
                    <>
                        <p>{text[props.currentLanguage].contact}</p>
                        <a  href={gmailLink} target="_blank" rel="noreferrer"><span><MdMailOutline/></span> parcerias@shiftappens.com</a>
                    </>
                )}
                {(props.footerInView || props.partnersInView) && (
                    // Render this content if either condition is true
                    <>
                        <a
                            
                            onClick={() => expandPartnersButton()}
                            >
                            <MdMailOutline/> {expanded ? 'parcerias@shiftappens.com' : ''}
                        </a>                 
                    </>
                )}
            </div>
        ) }
        </>
    )
}


export default PartnersMails