
import './App.css';
import {BrowserRouter, Routes, Route } from 'react-router-dom';
import LandingPage from './pages/LandingPage/LandingPage';
import RegisterPage from './pages/RegisterPage/RegisterPage';
import ConcludedRegistryPage from './pages/ConcludedRegistryPage/ConcludedRegistryPage';
import FAQPage from './pages/FAQPage/FAQPage';
import AboutPage from './pages/AboutPage/AboutPage';
import { useState } from 'react';
import { useMediaQuery } from 'react-responsive';

function App() {


  const [currentLanguage, setCurrentLanguage] = useState(localStorage.getItem('language') || 'PT');

  const navbarIsMobile = useMediaQuery({ query: '(max-width: 750px)' })
  const [openedMenu, setOpenedMenu] = useState(false);

  const changeLanguage = (language) => {  
    setCurrentLanguage(language);
    localStorage.setItem('language', language);
  }

  const toggleMenu = () => {
      
      setOpenedMenu(!openedMenu);
  }



  return (
    <BrowserRouter>
       <Routes>
            <Route path="/" element={<LandingPage currentLanguage={currentLanguage} setCurrentLanguage={changeLanguage} navbarIsMobile={navbarIsMobile} openedMenu={openedMenu} toggleMenu={toggleMenu}/>} />
            <Route path="/register" element={<RegisterPage currentLanguage={currentLanguage} setCurrentLanguage={changeLanguage}/>} />
            <Route path="/faq" element={<FAQPage currentLanguage={currentLanguage} setCurrentLanguage={changeLanguage} navbarIsMobile={navbarIsMobile} openedMenu={openedMenu} toggleMenu={toggleMenu}/>} />
            <Route path="/sobre" element={<AboutPage currentLanguage={currentLanguage} setCurrentLanguage={changeLanguage} navbarIsMobile={navbarIsMobile} openedMenu={openedMenu} toggleMenu={toggleMenu}/>} />  
        </Routes>
    </BrowserRouter>
  );
}

export default App;
