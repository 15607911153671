import Navbar from "../../components/Navbar/Navbar";
import { AboutPageCards, FAQPageCards }  from "../../components/Cards/Cards";
import './FAQPage.css';
import assets from '../../assets/index';
import { useState, useEffect } from 'react';


const FAQPage = (props) => {

    const [text, setText] = useState({});


    useEffect(() => {
        if (props.currentLanguage === "PT") {
            setText(textPT);
        } else {
            setText(textEN);
        }
    }, [])


    const textPT = {
        "faqTitle": "FAQ",
        "faqSubtitle": "Perguntas Frequentes",
        "faqList": [
            {
                "question": "Em que consiste o Shift APPens?",
                "answer1": "O Shift APPens é uma competição de programação e de empreendedorismo onde equipas de até 4 elementos desenvolvem um projeto no domínio da tecnologia durante 48 horas",
                "answer2": "Durante o hackathon poderás também contar com talks, workshops, mentorias, contacto com empresas, desafios personalizados, prémios, jogos, convívio e muita diversão!",
            },
            {
                "question": "Em que consiste o Shift Academy?",
                "answer1": "O Shift Academy é uma competição paralela ao decorrer do Shift APPens com o objetivo de suscitar o interesse na tecnologia e no empreendedorismo junto dos estudantes do ensino secundário. Estes estudantes devem organizar-se em equipas de até 4 elementos e desenvolver o projeto que lhes será proposto.",
                "answer2":"Durante a competição poderás também contar com formações, mentorias, prémios, jogos, convívio e muita diversão!"
            },
            {
                "question": "Como posso participar no Shift APPens ou no Shift Academy?",
                "answer1": "Para participar, apenas tens de entrar na aba das Inscrições, preencher o formulário e seguir os passos indicados. Garante já o teu lugar nesta 10ª edição!"
            },
            {
                "question": "Qual é a data e localização do evento?",
                "answer1": "A 10ª edição do Shift APPens decorrerá nos dias 19, 20 e 21 de abril de 2024, no pavilhão C.F. União de Coimbra, e será organizada pelo Núcleo de Estudantes de Informática da Associação Académica de Coimbra, em colaboração com a jeKnowledge"
            },
            {
                "question": "Qual é a duração do hackathon?",
                "answer1": "O evento terá a duração de 48 horas intensivas, mas bastante divertidas, onde os participantes poderão mergulhar de cabeça nos seus projetos."
            },
            {
                "question": "Há algum pré-requisito de habilidades para participar?",
                "answer1": "Não existe um pré-requisito específico. O evento é aberto a entusiastas de todos os níveis, desde iniciantes a programadores experientes. Se ainda és estudante do ensino secundário, vem participar no Shift Academy!"
            },
            {
                "question": "O evento é pago?",
                "answer1": "Sim, a participação no Shift APPens e no Shift Academy é paga. O valor da inscrição é de:",
                "answerpoint1": "8.5€ para bilhete Estudante",
                "answerpoint2": "20€ para bilhete Shift Academy",
                "answerpoint3": "20€ para bilhete Não Estudante"
            },
            {
                "question":"Qual é o tema da competição?",
                "answer1":"A competição principal do Shift APPens não tem um tema definido, portanto podes dar asas à tua imaginação e apresentar um projeto sobre qualquer assunto. Existirão ainda desafios proporcionados pelas empresas patrocinadoras do evento, esses sim poderão ter temas associados. Fica atento às nossas redes sociais para descobrires quais são."
            },
            {
                "question":"Como funciona o processo de avaliação dos projetos?",
                "answer1": "A avaliação dos projetos é feita por um incrível grupo de jurados, rigorosamente selecionados, que terão por base 4 critérios: o conceito, a implementação, o design e o pitch final."
            },
            {
                "question":"Quantos elementos deve ter uma equipa?",
                "answer1": "Para participar deves formar uma equipa de até 4 elementos. Caso te inscrevas individualmente a organização irá ajudar-te a encontrar uma equipa!"
            },
            {
                "question":"Quais são os prémios para os vencedores?",
                "answer1":"Os prémios serão anunciados em breve. Fica atento às nossas redes sociais para não perderes nada!"
            },
            {
                "question":"Como posso entrar em contacto com a equipa organizadora?",
                "answer1":"Se tiveres alguma dúvida, podes entrar em contacto connosco através do e-mail participantes@shiftappens.com . Estamos aqui para ajudar!"
            },
            {
                "question":"Como funcionam as refeições e a estadia?",
                "answer1":"O bilhete inclui refeições de qualidade, bem como várias opções de snacks, durante todo o evento. Serão garantidas opções vegetarianas e vegans em todas as refeições.",
                "answer2": "O local de estadia será no pavilhão C.F. União de Coimbra, local onde também decorrerá o evento. Para usufruíres de uma boa estadia aconselhamos que te faças acompanhar de um saco cama, colchonete e almofada."
            },
            {
                "question":"Existe algum suporte técnico durante o evento?",
                "answer1":"Sim, teremos uma equipa disponível para ajudar com quaisquer desafios ou dúvidas que possam surgir durante o hackathon."
            }

        ]        
    
    }

    const textEN = {
        "faqTitle": "FAQ",
        "faqSubtitle": "Frequently Asked Questions",
        "faqList": [
            {
                "question": "What is Shift APPens?",
                "answer1": "Shift APPens is a programming and entrepreneurship competition where teams of up to 4 members develop a project in the technology domain within a 48-hour timeframe.",
                "answer2": "Throughout the hackathon, participants can expect talks, workshops, mentorship sessions, interactions with companies, personalized challenges, prizes, games, networking, and lots of fun!"
            },
            {
                "question": "What is Shift Academy?",
                "answer1": "Shift Academy is a parallel competition to Shift APPens, its aim is to spark interest in technology and entrepreneurship among high school students.",
                "answer2": "Teams of up to 4 students will develop a project proposed to them. The competition includes training sessions, mentorship, prizes, games, networking, and plenty of fun!",
            },
            {
                "question": "How to participate in Shift APPens or Shift Academy?",
                "answer1": "To participate, simply go to the Registration section, fill out the form, and follow the indicated steps. Secure your spot in this 10th edition!"
            },
            {
                "question": "Date and location of the event?",
                "answer1": "The 10th edition of Shift APPens will take place on April 19, 20, and 21, 2024, at the C.F. União de Coimbra pavilion, organized by NEI/AAC and jeKnowledge",
            },
            {
                "question": "Duration of the hackathon?",
                "answer1": "The event will last for 48 intensive, yet enjoyable, hours where participants can fully immerse themselves in their projects."
            },
            {
                "question": "Any Skill prerequisites to participate?",
                "answer1": "No specific prerequisites exist. The event is open to enthusiasts of all levels, from beginners to experienced programmers. If you are a high school student, come and participate in Shift Academy"
            }   ,
            {
                "question": "Is there a registration fee?",
                "answer1": "Yes, participation in Shift APPens and Shift Academy is paid. The registration fee is:",
                "answerpoint1": "8.5€ for Student ticket",
                "answerpoint2": "20€ for Shift Academy ticket",
                "answerpoint3": "20€ for Non-Student ticket"
            },
            {
                "question": "Competition theme?",
                "answer1": "The main Shift APPens competition does not have a predefined theme, allowing projects on any subject. However, challenges provided by event sponsor companies may have associated themes. Stay tuned to our social media for updates."
            },
            {
                "question":"Project evaluation process?",
                "answer1":"Projects are evaluated by a selected group of judges based on four criteria: concept, implementation, design, and the final pitch."
            },
            {
                "question":"Team size?",
                "answer1":"Teams should consist of up to 4 members. If you register individually, the organization will assist you in forming a team."   
            },
            {
                "question":"Prizes for winners?",
                "answer1":"Prizes will be announced soon. Stay tuned to our social media to not miss anything!"
            },
            {
                "question":"Contacting the Organizing Team?",
                "answer1":"If you have any questions, feel free to contact us at [Insert Email]. We're here to help!"
            },
            {
                "question":"How do meals and accommodation work?",
                "answer1":"The ticket includes quality meals as well as various snack options throughout the event. Vegetarian and vegan options will be guaranteed at all meals.", 
                "answer2":"Your accommodation will be at the C.F. União de Coimbra court, where the event will also take place. To enjoy a good stay, we recommend that you bring a sleeping bag, mattress and pillow."
            },
            {
                "question":"Technical support during the event?",
                "answer1":"Yes, we will have a mentor team and a technical team available to assist with any challenges or questions that may arise during the hackathon."
            }   
        ]
    }

    console.log(text["faqSubtitle"])
    console.log(text.faqSubtitle)
    
    return (
        <div className="faq-page">
            <Navbar 
                currentLanguage={props.currentLanguage} 
                NavCarouselColorView={true} 
                toggleMenu={props.toggleMenu} 
                openedMenu={props.openedMenu} 
                isMobile={props.navbarIsMobile}
                navbarType="faq"
            />
            <div className="faq-page-container">
                <div className="faq-page-header">
                    <h1>FAQ</h1>
                    <svg xmlns="http://www.w3.org/2000/svg" width="32" height="33" viewBox="0 0 32 33" fill="none">
                        <path d="M18.0335 13.0342C18.3367 13.0339 18.6099 12.8511 18.7259 12.5709C18.8418 12.2907 18.7776 11.9683 18.5632 11.7539L13.9854 7.17617C12.685 5.87574 12.6855 3.76362 13.9906 2.45854C15.2957 1.15347 17.4078 1.15295 18.7082 2.45338L30.2555 14.0007C30.9057 14.6509 31.2315 15.5035 31.2308 16.3575C31.229 17.2136 30.9022 18.0665 30.2504 18.7183L18.6824 30.2863C17.3773 31.5914 15.2652 31.5919 13.9648 30.2915C13.3146 29.6413 12.9887 28.7887 12.9894 27.9346C12.9913 27.0785 13.3181 26.2257 13.9699 25.5739L18.5558 20.9879C18.7704 20.7733 18.8346 20.4506 18.7183 20.1703C18.602 19.89 18.3283 19.7073 18.0248 19.7076L4.7745 19.7195C2.93306 19.7211 1.44048 18.2285 1.44213 16.3871C1.44378 14.5446 2.94068 13.0477 4.78316 13.0461L18.0335 13.0342Z" fill="#314D7B" stroke="#314D7B" stroke-width="1.5" stroke-linejoin="round"/>
                    </svg>
                    <h3>{text["faqSubtitle"]}</h3>
                </div>
                <div className="faq-page-dropdown-container">
                    {text.faqList && text.faqList.map((item, index) => (
                        <div key={index} className={`${(index +1 )% 2 === 0 ? 'faq-right-dropdown' : ''}`}>
                            <FAQPageCards currentLanguage={props.currentLanguage} isMobile={props.navbarIsMobile} question={item.question} item={item} />
                        </div>
                    ))}
                </div>
            </div>
            <div className="faq-page-floating-icons-container">
            <img className="faq-page-floating-icon" src={assets.faq_star_icon} alt="Floating Icon" style={{color:"#4A73B8"}}></img>
            <img className="faq-page-floating-ball" src={assets.faq_page_ball} alt="Floating ball"></img> 
            </div>
        </div>

    )

}

export default FAQPage;






