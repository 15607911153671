
import assets from '../../assets/index'
import './Footer.css'

const Footer = (props) => {

    const footerText = {
        "PT": {
            "feitos": "Feito com amor por nós",
        },
        "EN": {
            "feitos": "Made with love by us",
        }
    };

    return (
        <div className="footer">
            <div className="footer-container">
                <div className="footer-authors">
                    <p>{footerText[props.currentLanguage].feitos}</p>
                    <div>
                    <a href="https://nei.dei.uc.pt/" target="_blank" rel="noopener noreferrer">
                        <img src={assets.nei_logo} alt="nei_logo"/>
                    </a>
                    <a href="https://www.jeknowledge.pt/" target="_blank" rel="noopener noreferrer">
                        <img src={assets.jek_logo} alt="jek_logo" />
                    </a>
                    </div>
                    
                </div>
                
                <div className="footer-header">
                    <h4>10 years making it APPen</h4>
                </div>
                

                <div className="footer-social-logos">

                <a href="https://www.instagram.com/shiftappens/" target="_blank" rel="noopener noreferrer">
                    <img src={assets.insta_logo} alt="Instagram" />
                </a>
                <a href="https://tiktok.com" target="_blank" rel="noopener noreferrer">
                    <img src={assets.tiktok_logo} alt="TikTok" />
                </a>
                <a href="https://twitter.com/ShiftAPPens" target="_blank" rel="noopener noreferrer">
                    <img src={assets.twitter_logo} alt="Twitter" />
                </a>
                <a href="https://www.linkedin.com/company/shift-appens/?originalSubdomain=pt" target="_blank" rel="noopener noreferrer">
                    <img src={assets.linkedin_logo} alt="LinkedIn" />
                </a>
                </div>
            </div>
        </div>
    )
}

export default Footer;