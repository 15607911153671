import { useMediaQuery } from 'react-responsive';
import './FormsPopup.css';


const FormsPopup = (props) => {

    const isMobile = useMediaQuery({ query: '(max-width: 450px)' })

    const popupText = {
        "PT": {
            "header1": "Parece que estás a tentar sair",
            "header1mobile": "Parece que estás ",
            "header2mobile": "a tentar sair",
            "header2": "Se saires, vais perder todo o progresso",
            "leave": "Sair mesmo assim",
            "continue": "Continuar a preencher",
            },
        "EN": {
            "header1": "It seems you are trying to leave",
            "header1mobile": "It seems you are ",
            "header2mobile": "trying to leave",
            "header2": "If you leave, you will lose all progress",
            "leave": "Leave anyway",
            "continue": "Continue",
        }
    }


    
    return (
        <div className="forms-leaving-popup">
            <div className="forms-leaving-popup-container">
                <div className="forms-leaving-close-button">
                    <svg xmlns="http://www.w3.org/2000/svg" width="19" height="19" viewBox="0 0 19 19" fill={props.palette.mainColor} onClick={() => props.setOpenLeavingPopup(false)}>
                        <path d="M17.2628 12.8252C17.2706 12.833 17.2786 12.8406 17.2867 12.848C18.4806 14.0593 18.4804 16.0084 17.2802 17.2172L17.2799 17.2176C16.7141 17.7882 15.9832 18.0927 15.2393 18.1321L15.239 18.1321C14.3938 18.1772 13.5351 17.8784 12.8854 17.2356C12.8852 17.2354 12.8851 17.2353 12.885 17.2352L10.0987 14.4705C9.94457 14.3176 9.73597 14.2321 9.51881 14.233C9.30165 14.2338 9.09373 14.3209 8.94078 14.4751L6.17558 17.2623L6.17556 17.2623C4.96661 18.4809 2.99979 18.4883 1.7827 17.2798L1.78225 17.2793C0.564283 16.0718 0.556165 14.1048 1.76403 12.886C1.76411 12.886 1.76419 12.8859 1.76427 12.8858L4.52924 10.0988C4.8477 9.77784 4.84567 9.25946 4.52469 8.94098L1.73767 6.17559L1.73766 6.17557C0.519221 4.96664 0.511516 3.00066 1.72044 1.78207L1.7206 1.78191C2.28642 1.21126 3.01624 0.906827 3.76106 0.867472L3.76148 0.86745C4.60667 0.822357 5.46519 1.12112 6.11484 1.76495C6.11485 1.76496 6.11486 1.76497 6.11487 1.76498C6.11492 1.76503 6.11497 1.76508 6.11502 1.76513L8.90186 4.52913C9.22288 4.84752 9.74122 4.84543 10.0597 4.52446L12.8247 1.73748C12.8247 1.73741 12.8248 1.73733 12.8249 1.73726C14.0341 0.519603 15.9999 0.511411 17.218 1.72002L17.2181 1.72018C17.7886 2.28592 18.0932 3.01698 18.1325 3.7609L18.1325 3.76127C18.1776 4.60656 17.8789 5.46516 17.2351 6.11485C17.235 6.11491 17.235 6.11497 17.2349 6.11503L14.4711 8.90204C14.1527 9.22306 14.1548 9.74134 14.4758 10.0598L17.2628 12.8252Z" fill="#EB6F29" stroke="#EB6F29" stroke-width="1.63753" stroke-linejoin="round"/>
                    </svg>
                </div>
                <div className="forms-leaving-popup-header">
                    <span>:(</span>
                    {!isMobile ? 
                        <h2 style={{color: props.palette.mainColor}}>{popupText[props.currentLanguage]["header1"]}</h2> 
                    :                         
                    <h2 style={{color: props.palette.mainColor}}>{popupText[props.currentLanguage]["header1mobile"]}<br></br>{popupText[props.currentLanguage]["header2mobile"]}</h2> 
                    }
                    
                    <h3 style={{color: props.palette.valueColor}}>{popupText[props.currentLanguage]["header2"]}</h3>
                </div>
                <div className="forms-leaving-popup-buttons">
                    <button className="forms-leave-button" onClick={() => props.goToHome()}>{popupText[props.currentLanguage]["leave"]}</button>
                    <button className="forms-continue-button" onClick={() => props.setOpenLeavingPopup(false)}>{popupText[props.currentLanguage]["continue"]}</button>
                </div>
            </div>
        </div>

    )

}


export default FormsPopup;