import {useState, useEffect} from 'react'
import "./RegisterPage.css"
import LanguageChanger from '../../components/LanguageChanger/LanguageChanger';
import assets from "../../assets/index"
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import FormStructure from '../../components/RegisterForms/RegisterForms';
import ConcludedRegistryPage from '../ConcludedRegistryPage/ConcludedRegistryPage';
import { useMediaQuery } from 'react-responsive';
import { validate } from 'react-email-validator';

import { IoMdArrowRoundBack } from "react-icons/io";
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { useLocation } from 'react-router-dom';
import FormsPopup from '../../components/FormsPopup/FormsPopup';


const RegisterPage = (props) => {



    const navigate = useNavigate();
    const [missingFields, setMissingFields] = useState([]);
    const [shiftType, setShiftType] = useState(null);
    const [validEmail, setValidEmail] = useState(true);

    const [loading, setLoading] = useState(true);
    const [currentPage, setCurrentPage] = useState(0);
    const [formArrowHovered, setFormArrowHovered] = useState(false);
    const [openLeavingPopup, setOpenLeavingPopup] = useState(false);
    


    const [loadingServerResponse, setLoadingServerResponse] = useState(true);  
    const [loadingPage, setLoadingPage] = useState(false);
    const [swipeAxis, setSwipeAxis] = useState('horizontal');

    const mainBackgroundColors = {
        0: "#FBE2D4",
        1: "#FCE6EC",
        2: "#E5EDFB",
        3: "#DBF0E3",
        4: "#F9D6DC",
    }

    const [validPhone, setValidPhone] = useState(true);
    
    const [allowedChangeStep, setAllowedChangeStep] = useState(false);

    const [formStructureDataEn, setFormStructureDataEn] = useState([
        {
            step: 1,
            name: "Personal Information",
            palette: 
                {
                    miniText: "rgba(235, 111, 41, 0.50)",
                    mainColor: "#EB6F29",
                    backgroundColor: "#F8CFB8",
                    valueColor: "#9D4A1B"
                },
            inputs: [{
                        label: "First and last name *",
                        type: "text",
                        placeholder: "Enter your first and last name",
                        value: "name",
                    },
                    
                    {
                        label: "E-mail *",
                        type: "email",
                        placeholder: "Enter your e-mail",
                        value: "email",
                    }
                    
                    ],
            miniInputs: [{
                        label:"Age *",
                        type: "number",
                        placeholder: "Enter your age",
                        value: "age",
                    },
                    {
                        label: "Phone *",
                        type: "number",
                        placeholder: "Enter your phone number",
                        value: "phone",
                    }
                ],

        },
        {
            step: 2,
            name: "Occupation",
            dropdownInputs: [{
                        label: "Student/Worker *",
                        placeholder: "Select your occupation",
                        value: "occupation",
                        options: ["Student", "Worker", "Unemployed"],
                    }],
            inputs: [      {
                        label:"Location *",
                        type: "text",
                        placeholder: "Enter the location where you study or work",
                        value: "local",
                    },
                    {
                        label:"Course/Profession *",
                        type: "text",
                        placeholder: "Enter your course or professional position",
                        value: "work",
                    },
                    ],
                    
            palette:
                {
                    miniText: "rgba(239, 128, 162, 0.50)",
                    mainColor: "#EF80A2",
                    backgroundColor: "#FAD5E0",
                    pageBackground: "#FCE6EC",
                    valueColor: "#9F556C"
                }
        }, {
            step: 3,
            name: "More Information",
            dropdownInputs: [{
                        label: "T-Shirt Size *",
                        placeholder: "Select your T-Shirt size",
                        value: "tshirtSize",
                        options: ["XS", "S", "M", "L", "XL", "XXL"],
                    }],
            inputs: [   {
                        label:"Food Restrictions",
                        type: "text",
                        placeholder: "Enter your food restrictions",
                        value: "foodRestrictions",
                        },
                    ],
            checkboxInputs:
                    [
                        {
                            label:"No" ,
                            value:"vegan",
                            option: "no"
                        },
                        {
                            label:"Yes, vegetarian",
                            value:"vegan",
                            option: "vegetarian"
                        },
                        {
                            label:"Yes, vegan",
                            value:"vegan",
                            option: "vegan"
                    
                        }
                    ],
            palette:
                {
                    miniText: "rgba(74, 115, 184, 0.50)",
                    mainColor: "#4A73B8",
                    backgroundColor: "#DBE3F1",
                    pageBackground: "#E5EDFB",
                    valueColor: "#314D7B"
                }
        },  
        {
            step: 4,
            name: "Others",
            inputs: [{
                        label:"How did you hear about Shift? ",
                        type: "text",
                        placeholder: "Enter your answer",
                        value: "shiftExperience",
                    },
                    {
                        label:"CV that will be sent to companies ",
                        type: "file",
                        placeholder: "Upload your CV that will be sent to companies",
                        value: "cv",
                    }],
            checkboxInputs: [
                        {
                        label:"I declare that I have read and accept all the terms and conditions of the event." ,
                        value:"agreement",
                        option: "yes"

                        },
                        {
                            label:"I consent to my CV being sent to the event's sponsoring companies.",
                            value:"cvConsent",
                            option: "yes",
                        },
                    ],
            palette: {
                    miniText: "rgba(75, 178, 116, 0.50)",
                    mainColor: "#4BB274",
                    backgroundColor: "#C3E5D1",
                    valueColor: "#32774D"
            }
        }, {
            step: 5,
            name: "Payment",
            text: {
                span: "Early Bird",
                h1:"Amount to pay: 0.00€",
                h2:"Payment details",
                paymentInfo: [{
                                header: "jeKnowledge Associção",
                                info: "jeKnowledge Associação"
                            },
                            {
                                header: "IBAN",
                                info: "IBAN: PT50 0045 3030 4022 3802 8161 8 (Crédito Agrícola)"
                                
                            }]
            },
            inputs: [{
                        label: "Payment proof *",
                        type: "file",
                        placeholder: "Upload the payment proof",
                        value: "paymentProof",
                    }],
            miniInputs: [{
                        label: "Taxpayer Number",
                        type: "text",
                        placeholder: "Enter your NIF",
                        value: "nif",
                    },
                    {
                        label: "Entity for receipt",
                        type: "text",
                        placeholder: "Enter the entity",
                        value: "receiptEntity",
                    }
                ],
            warning: "The submitted data will only be processed by the Shift APPens organizing team and will be deleted within 30 days after the event.",
            button: "Submit",
            palette:
                {
                    miniText: "rgba(227, 49, 78, 0.50)",
                    mainColor: "#E3314E",
                    backgroundColor: "#DBE3F1",
                    valueColor:"#972134"

                }
        }
    ])

    const [formStructureDataPT, setFormStructureDataPT] = useState([
        {
            step: 1,
            name: "Informações Pessoais",
            palette: 
                {
                    miniText: "rgba(235, 111, 41, 0.50)",
                    mainColor: "#EB6F29",
                    backgroundColor: "#F8CFB8",
                    valueColor: "#9D4A1B"
                },
            inputs: [{
                        label: "Primeiro e último nome *",
                        type: "text",
                        placeholder: "Introduz o teu primeiro e último nome ",
                        value: "name",
                    },
                    
                    {
                        label: "E-mail *",
                        type: "email",
                        placeholder: "Introduz o teu e-mail",
                        value: "email",
                    }
                    
                    ],
            miniInputs: [{
                        label:"Idade *",
                        type: "number",
                        placeholder: "Introduz a tua idade",
                        value: "age",
                    },
                    {
                        label: "Telemóvel *",
                        type: "number",
                        placeholder: "Introduz o teu número de telemóvel",
                        value: "phone",
                    }
                ],
            
        },
        {
            step: 2,
            name: "Ocupação",
            dropdownInputs: [{
                        label: "Estudante/Trabalhador *",
                        placeholder: "Seleciona a tua ocupação",
                        value: "occupation",
                        options: ["Estudante", "Trabalhador", "Desempregado"],
                    }],
            inputs: [      {
                        label:"Local *",
                        type: "text",
                        placeholder: "Introduz o local onde estudas ou trabalhas",
                        value: "local",
                    },
                    {
                        label:"Curso/Profissão *",
                        type: "text",
                        placeholder: "Introduz o teu curso ou cargo profissional",
                        value: "work",
                    },
                    ],
            
            palette: 
                {
                    miniText: "rgba(239, 128, 162, 0.50)",
                    mainColor: "#EF80A2",
                    backgroundColor: "#FAD5E0",
                    pageBackground: "#FCE6EC",
                    valueColor: "#9F556C"
                }
        }, {
            step: 3,
            name: "Mais Informações",
            dropdownInputs: [{
                        label: "Tamanho da T-Shirt *",
                        placeholder: "Seleciona o tamanho da tua T-Shirt",
                        value: "tshirtSize",
                        options: ["XS", "S", "M", "L", "XL", "XXL"],
                    }],
            inputs: [   {
                        label:"Restrições Alimentares",
                        type: "text",
                        placeholder: "Introduz as tuas restrições alimentares",
                        value: "foodRestrictions",
                        },
                    ],
            checkboxInputs: 
                    [
                        {
                            label:"Não" ,
                            value:"vegan",
                            option: "no"
                        }, 
                        {
                            label:"Sim, vegetariano(a)",
                            value:"vegan",
                            option: "vegetarian"
                        },
                        {
                            label:"Sim, vegan",
                            value:"vegan",
                            option: "vegan"

                        }

                    ], 
            palette:
                {
                    miniText: "rgba(74, 115, 184, 0.50)",
                    mainColor: "#4A73B8",
                    backgroundColor: "#DBE3F1",
                    pageBackground: "#E5EDFB",
                    valueColor: "#314D7B"
                }
        },
        {
            step: 4,
            name: "Outros",
            inputs: [{
                        label:"Como ouviste falar do Shift? ",
                        type: "text",
                        placeholder: "Introduz a tua resposta",
                        value: "shiftExperience",    
                    },
                    {
                        label:"CV que será enviado a empresas ",
                        type: "file",
                        placeholder: "Carrega o teu CV que será enviado às empresas",
                        value: "cv",
                    }], 
        
            checkboxInputs: [
                        {
                        label:"Declaro que li e aceito todos os termos e condições do evento." ,
                        value:"agreement",
                        option: "yes"
                        
                        }, 
                        {
                            label:"Consinto que o meu CV seja enviado às empresas patrocinadoras do evento.",
                            value:"cvConsent",
                            option: "yes",
                        },
                        

                    ],  
            palette: {
                    miniText: "rgba(75, 178, 116, 0.50)",
                    mainColor: "#4BB274",
                    backgroundColor: "#C3E5D1",
                    valueColor: "#32774D"
            }    
        }, {
            step: 5,
            name: "Pagamento",
            text: {
                span: "Early Bird",
                h1:"Quantia a pagar: 0.00€",
                h2:"Dados do pagamento",
                paymentInfo: [{
                                header: "jeKnowledge Associção",
                                info: "jeKnowledge Associação"
                            },
                            {
                                header: "IBAN",
                                info: "IBAN: PT50 0045 3030 4022 3802 8161 8 (Crédito Agrícola)"

                            }]
            },
            inputs: [{
                        label: "Comprovativo de pagamento *",
                        type: "file",
                        placeholder: "Carrega o comprovativo de pagamento",
                        value: "paymentProof",
                    }],
            miniInputs: [{
                        label: "Nº de Contribuinte",
                        type: "text",
                        placeholder: "Introduz o teu NIF",
                        value: "nif",
                    },
                    {
                        label: "Entidade para recibo",
                        type: "text",
                        placeholder: "Introduz a entidade",
                        value: "receiptEntity",
                    }
                ],
            warning: "Os dados submetidos serão apenas tratados pela equipa organizadora do Shift APPens e serão eliminados até 30 dias após o evento.",
            button: "Submeter",
            palette:
                {
                    miniText: "rgba(227, 49, 78, 0.50)",
                    mainColor: "#E3314E",
                    backgroundColor: "#DBE3F1",
                    valueColor:"#972134"

                }
        }
    ])

    const formStructureData = props.currentLanguage === "PT" ? formStructureDataPT : formStructureDataEn;


    const goToHome = () => {
        navigate('/');
    }

    const [formData, setFormData] = useState({
        name: "" , //text field,   
        age: "" , //number field
        email: "", //email field com verificação
        phone: "", //number field
        occupation: "", //tem varias hipoteses (dropdown) mas basicamente e um text
        local: "", //text field
        work: "", //text field
        tshirtSize: "", // tem varias hipoteses (dropdown) mas basicamente e um text
        foodRestrictions: "", //text field
        vegan:"",  // sao 3 checkbox em que escolhe uma opcao, pode ser text field e apenas envio a opcao escolhida
        shiftExperience: "", //text field
        cv: "", //file
        paymentProof: "", // file
        nif: "", //text ou number field 
        receiptEntity: "", //text field
        responsibilityTerm: "", //file
        agreement: "",  //boolean  checkbox tem que estar assinalada
        cvConsent: "", // boolean checkbox, pode nao estar assinalado,
    })

    const handleInputChange = (name, event) => {
        
        setMissingFields([]);
        
        if ( name === "phone") {
            const { value } = event.target;
            const validPhone = value.length === 9;
            setValidPhone(validPhone);
        } else if (name === "email") {
            const { value } = event.target;
            validateEmail(value);
        }

        if (name==="cv" || name==="paymentProof" || name==="responsibilityTerm") {
            
            const file = event.target.files[0];

            if (file === undefined || file === null) {

                setFormData((prevData) => ({
                    ...prevData,
                    [name]: "",
                }));
            }
            else { 
                setFormData((prevData) => ({
                    ...prevData,
                    [name]: file,
                }));
            }
        }
        else {
            const { value } = event.target;
        
            setFormData((prevData) => ({
                ...prevData,
                [name]: value,
            }));
        }
        
        
        
    };


        
    const checkMissingFields = (fields, formData) => {

        const newMissingFields = fields.filter((field) => formData[field] === "");
        setMissingFields(newMissingFields);
    
        return newMissingFields;
    };


    const handleFormsSubmit = async () => {
        
        if ( formData.paymentProof === "") {
            checkMissingFields(["paymentProof"], formData);
            return 
        }
   

        try {
            //axios call to the backend post the formData to this backend route: http://HOST:8080/api/form
            //if the response is 200, then set the loadingPage to false and the loadingServerResponse to true do it


            setLoadingPage(true);
            await axios.post('https://api.shiftappens.com/api/form', formData, {
                headers:{
                    'Content-Type': 'multipart/form-data'
                }
            })
            .then((response) => {
                if (response.status === 200) {
                    setLoadingServerResponse(false);
                }
                else {
                    alert("Erro no servidor, por favor tente novamente")
                    setLoadingServerResponse(false);
                }
    
            })
            .catch((error) => {
                console.log(error);
            })

        }
        catch (error) {
            console.log(error);
        }
    }

    
    function validateEmail(email) {


        if (validate(email)) {
            setValidEmail(true);
            return true;
        }
        else {
            setValidEmail(false);
            return false;
        }
        
    }

    
    const handleNextPage = () => {   
        

        if (currentPage === 0 )  {
            
            const newMissingFields = checkMissingFields(["name", "email","age", "phone",], formData);
            const validEmail = validateEmail(formData.email);
            const validPhone = formData?.phone?.length === 9;
            if (validEmail === false || validPhone === false) {
                setValidPhone(false);
                return;
            }
            checkContinue(newMissingFields);
        }
        if (currentPage === 1 )  {
            const newMissingFields = checkMissingFields([ "occupation", "local", "work"], formData);
            checkContinue(newMissingFields);
        }
        if (currentPage === 2 )  {
            const newMissingFields = checkMissingFields(["tshirtSize"], formData);
            checkContinue(newMissingFields);
        }
        if (currentPage === 3 )  {
            if (shiftType === "academy" || formData.age < 18) {
                const newMissingFields = checkMissingFields(["responsibilityTerm", "agreement"], formData);      
                checkContinue(newMissingFields);
            }
            else {
                const newMissingFields = checkMissingFields(["agreement"], formData);
                
                checkContinue(newMissingFields);     
            }
            
        }   


        
        
    }

    const checkContinue = (newMissingFields) => {
       
        if (newMissingFields.length === 0) {
            setCurrentPage((prevPage) => Math.min(4, prevPage + 1));
        }
    }
 
    const handlePrevPage = () => {
        setCurrentPage((prevPage) => Math.max(0, prevPage - 1));
    };

    useEffect(() => {
        setTimeout(() => setLoading(false),2000);
    }
    , []);

   

    const handleKeyDown = (event) => {
        switch (event.key) {
            case 'Escape':
                if (currentPage === 0) {
                    setOpenLeavingPopup(true);
                    
                }
                else {
                    handlePrevPage();
                }
                break;
            case 'Enter':
                
                handleNextPage();
                break;
            default:
                break;
        }
    };

    useEffect(() => {
        document.addEventListener('keydown', handleKeyDown);

        return () => {
            document.removeEventListener('keydown', handleKeyDown);
        };
    }, [formData, currentPage]); // Add currentPage as a dependency to reattach the event listener when currentPage changes

    const isMobile = useMediaQuery({ query: '(max-width: 700px)' })
    

    useEffect(() => {
        const handleResize = () => {
            setSwipeAxis(isMobile ? 'vertical' : 'horizontal');
        };
    
        window.addEventListener('resize', handleResize);
    
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, [isMobile]);


    const registerText = {
        "PT": {
            "header1":"As inscrições para o Shift APPens 2024 ",
            "header2":"estão oficialmente abertas!",
            "header1mobile":"As inscrições para o Shift ",
            "header2mobile":"APPens 2024 ",
            "header3mobile":"estão oficialmente abertas!",
            "goback":"Voltar",
            "inscricao":"Inscrições",
            "about":"Vem viver a",
            "diversao":"diversão, ",
            "desafios":"os desafios",
            "e":" e ",
            "inovacao":"inovação",
            "naoapenas":"no Shift APPens!",
            "inscreve1":"Não percas a oportunidade de participar, ",
            "inscreve2":"inscreve-te agora ",
            "inscreve3":"e abre portas a uma experiência que vai muito para além das linhas de código.",
            "aventura":"Estás pronto para a aventura?",
            "esc1": "Aperta",
            "esc2": "ESC",
            "esc3": "para voltar atrás",
            "enter1": "Aperta",
            "enter2": "ENTER",
            "enter3": "para avançar",
        },
        "EN": {
            "header1":"Registrations for Shift APPens 2024 ",
            "header2":"are officially open!",
            "header1mobile":"Registrations for Shift ",
            "header2mobile":"APPens 2024 ",
            "header3mobile":"are officially open!",
            "goback":"Go back",
            "inscricao":"Registrations",
            "about":"Come and experience the",
            "diversao":"fun",
            "desafios":"the challenges",
            "e":" and ",
            "inovacao":"innovation",
            "naoapenas":" at Shift APPens!",
            "inscreve1":"Don't miss the opportunity to participate, ",
            "inscreve2": "register now ",
            "inscreve3":" and open doors to an experience that goes far beyond lines of code.",
            "aventura":"Are you ready for the adventure?",
            "esc1": "Press",
            "esc2": "ESC",
            "esc3": "to go back",
            "enter1": "Press",
            "enter2": "ENTER",
            "enter3": "to go forward",
        }

    }

    async function checkParticipantsLimit() {
        //axios call to the backend to check if the participants limit has been reached
        const response = await axios.get('https://api.shiftappens.com/api/form/registrations')
        console.log(response.data.message)
        if (response.data.message === 210) {
            alert("As incrições encontram-se encerradas!")
            goToHome();
        }

    }

    
    return (
        <div className={`${loadingPage ? 'register-loading-container' : 'register-page'}`}>
            {loading ? (
                <div className="register-page-loading">
                    <div>
                        {!isMobile ? (
                        <p>{registerText[props.currentLanguage]["header1"]}<br />{registerText[props.currentLanguage]["header2"]}</p>
                        ) : (
                        <p>{registerText[props.currentLanguage]["header1mobile"]}<br />{registerText[props.currentLanguage]["header2mobile"]}<br />{registerText[props.currentLanguage]["header3mobile"]}</p>
                        )}
                    </div>
                </div>
            ) : null}

            {!loading && shiftType === null ? (
                <div className="register-page-home">
                    <div className="register-home-container">
                        <div className="register-home-language-changer">
                            <div onClick={() => goToHome()}><IoMdArrowRoundBack /> {registerText[props.currentLanguage]["goback"]}</div>
                            <LanguageChanger register={true} currentLanguage={props.currentLanguage} setCurrentLanguage={props.setCurrentLanguage}/>
                        </div>

                        <div className="register-home-container-info">
                            <div><h1>{registerText[props.currentLanguage]["inscricao"]}</h1></div>
                        <div className="register-home-text">
                            <p className="about-page-text ">{registerText[props.currentLanguage]["about"]} <span className="bold-text">{registerText[props.currentLanguage]["diversao"]}</span> <span className="bold-text">{registerText[props.currentLanguage]["desafios"]}</span>{registerText[props.currentLanguage]["e"]}<span className="bold-text">{registerText[props.currentLanguage]["inovacao"]}</span> {registerText[props.currentLanguage]["naoapenas"]}</p>
                            <p className="about-page-text ">{registerText[props.currentLanguage]["inscreve1"]} <span className="bold-text">{registerText[props.currentLanguage]["inscreve2"]}</span> {registerText[props.currentLanguage]["inscreve3"]} </p>
                        </div> 
                            <h6>{registerText[props.currentLanguage]["aventura"]}</h6>
                        <div className="register-home-buttons">
                            <button>
                                <img onClick={() => { 
                                    checkParticipantsLimit();
                                    setShiftType('shift') }} src={assets.main_logo} alt={registerText[props.currentLanguage]["buttonAlt1"]} />
                            </button>
                            <button >
                                <img onClick={() => { setShiftType('academy') }} src={assets.shift_academy_logo} className="register-home-button-img" alt={registerText[props.currentLanguage]["buttonAlt2"]} />
                            </button>
                        </div>
                    </div>
                </div>
                <div className="register-page-image">
                </div>
                    
                </div>
            ) : null }
            {!loading && !loadingPage && shiftType !== null ? (
                <div className= 'register-forms-page' style={{backgroundColor: mainBackgroundColors[currentPage]}} > 
                    {!loading ? (

                        <div className= {` register-forms-carousel ${ currentPage=== 4 ? 'register-forms-final-step' : ''}`}>
                            
                            <div className='forms-carousel-arrow'>
                                <div>
                                    <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320.29 320.28"
                                        fill={formArrowHovered==="left" ? formStructureData[currentPage].palette.mainColor : "none"}
                                        onMouseEnter={() => setFormArrowHovered("left")}
                                        onMouseLeave={() => setFormArrowHovered(false)}
                                        onClick={() => {
                                        currentPage !== 0 ? handlePrevPage() : setOpenLeavingPopup(true);
                                        setFormArrowHovered(false);
                                    }}>
                                        <path className="cls-1" stroke={formStructureData[currentPage].palette.mainColor} strokeWidth="10"
                                        d="M275.44,119.37c22.51,0,40.76,18.26,40.77,40.77,0,22.51-18.26,40.76-40.77,40.77h-132.18s45.71,45.7,45.71,45.7c15.92,15.92,15.92,41.73,0,57.65-15.92,15.92-41.73,15.92-57.65,0L16.03,188.96c-7.96-7.96-11.94-18.39-11.95-28.82,0-10.43,3.99-20.87,11.95-28.82L131.32,16.02c15.92-15.92,41.73-15.92,57.65,0,7.96,7.96,11.94,18.39,11.95,28.82,0,10.43-3.99,20.87-11.95,28.82l-45.71,45.71h132.18Z"/>
                                    </svg>
                                    <span style={{color: formStructureData[currentPage].palette.mainColor}}>{registerText[props.currentLanguage]["esc1"]} <span className="bold-text">{registerText[props.currentLanguage]["esc2"]}</span> {registerText[props.currentLanguage]["esc3"]}</span>
                                </div>
                            </div>
                            

                        <Carousel 
                        selectedItem={currentPage} 
                        showArrows={false} 
                        showThumbs={false} 
                        showStatus={false} 
                        showIndicators={false}
                        draggable={false}
                        swipeable={false}
                        axis={swipeAxis}
                        >
                            {[0,1,2,3,4].map((page) => (
                                
                                <FormStructure 
                                    shiftType={shiftType} 
                                    key={page} 
                                    currentPage={page} 
                                    handleInputChange={handleInputChange} 
                                    formStructureData={formStructureData} 
                                    formData={formData} 
                                    handleFormsSubmit={handleFormsSubmit} 
                                    missingFields={missingFields}
                                    validEmail={validEmail}
                                    currentLanguage={props.currentLanguage}
                                    validPhone={validPhone} 
                                    
                                />
                            ))}
                            </Carousel>
                            {currentPage !== 4 ? (
                            <div className="forms-carousel-arrow">
                                <div>
                                    <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320.29 320.28" 
                                        onClick={() => {
                                            handleNextPage(); 
                                            setFormArrowHovered(false)}}
                                        fill={formArrowHovered === "right" ? formStructureData[currentPage].palette.mainColor : "none"}
                                        onMouseEnter={() => setFormArrowHovered("right")}
                                        onMouseLeave={() => setFormArrowHovered(false)}
                                        >
                                        <path className="cls-1" stroke={formStructureData[currentPage].palette.mainColor} strokeWidth="10"
                                        d="M275.44,119.37c22.51,0,40.76,18.26,40.77,40.77,0,22.51-18.26,40.76-40.77,40.77h-132.18s45.71,45.7,45.71,45.7c15.92,15.92,15.92,41.73,0,57.65-15.92,15.92-41.73,15.92-57.65,0L16.03,188.96c-7.96-7.96-11.94-18.39-11.95-28.82,0-10.43,3.99-20.87,11.95-28.82L131.32,16.02c15.92-15.92,41.73-15.92,57.65,0,7.96,7.96,11.94,18.39,11.95,28.82,0,10.43-3.99,20.87-11.95,28.82l-45.71,45.71h132.18Z"/>
                                    </svg>
                                    <span style={{color: formStructureData[currentPage].palette.mainColor}}>{registerText[props.currentLanguage]["enter1"]} <span className="bold-text">{registerText[props.currentLanguage]["enter2"]}</span> {registerText[props.currentLanguage]["enter3"]}</span>
                                    
                                
                                </div>
                            </div>
                            ) : <div className="forms-carousel-arrow"></div>}
                        </div>

                    ) : null}
                        { openLeavingPopup ? <FormsPopup goToHome={goToHome} setOpenLeavingPopup={setOpenLeavingPopup}  setFormData={setFormData} currentLanguage={props.currentLanguage} palette={formStructureData[currentPage].palette} /> : null }
                </div>
            ) : null} 

        {loadingPage ? (<ConcludedRegistryPage loading={loadingServerResponse} email={formData.email} currentLanguage={props.currentLanguage}/>) : null}
        </div>
        
            
    )

}

export default RegisterPage