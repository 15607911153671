
import "./SectionChanger.css"

import PartnersMails from '../PartnersMail/PartnersMail'

const SectionChanger = (props) => {



    const svgColor= {
        fill: props.color,
        stroke: props.color
    }

    const divColor = {
        backgroundColor: props.color
    }

    return (
        <div className="section-changer">
            <svg xmlns="http://www.w3.org/2000/svg" width="2" height="124" viewBox="0 0 2 124" fill="none">
                    <path d="M0.999993 124L1 0" stroke={props.color} strokeWidth="2"/>
            </svg>
                <div className="section-changer-partners-container">
                    <div style={divColor} className="section-changer-text-container">
                        <p className="button-text">{props.text}</p>
                    </div>
                    {props.contactProp ? (
                    <PartnersMails partnersInView= {props.partnersInView} footerInView={props.footerInView} currentLanguage={props.currentLanguage} />
                    ) : null }
                </div>
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="70" viewBox="0 0 16 70" fill="none">
                <path d="M7.2929 69.7071C7.68342 70.0976 8.31659 70.0976 8.70711 69.7071L15.0711 63.3431C15.4616 62.9526 15.4616 62.3195 15.0711 61.9289C14.6805 61.5384 14.0474 61.5384 13.6569 61.9289L8 67.5858L2.34315 61.9289C1.95262 61.5384 1.31946 61.5384 0.928935 61.9289C0.538411 62.3195 0.538411 62.9526 0.928935 63.3431L7.2929 69.7071ZM7 4.37114e-08L7 69L9 69L9 -4.37114e-08L7 4.37114e-08Z" fill={props.color}/>
            </svg>
        </div>
    )
}

export default SectionChanger