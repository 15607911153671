import about_page_arrow from './about-page-arrow.svg'
import about_page_icon1 from './about-page-icon1.svg'
import about_page_icon2 from './about-page-icon2.svg'
import about_page_icon3 from './about-page-icon3.svg'
import about_page_icon4 from './about-page-icon4.svg'
import about_page_icon5 from './about-page-icon5.svg'
import about_page_icon6 from './about-page-icon6.svg'



export default {
    about_page_arrow,
    about_page_icon1,
    about_page_icon2,
    about_page_icon3,
    about_page_icon4,
    about_page_icon5,
    about_page_icon6
}