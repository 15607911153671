import { useState, useRef} from 'react'
import "./AboutSection.css"
import assets from "../../assets/index"
import { useMediaQuery } from 'react-responsive';


const AboutSection = (props) => {

    const [isPlaying, setPlaying] = useState(true);
    const videoRef = useRef(null);

    const aboutText = {
        "PT": {
            "hackathon1": "O Hackathon mais antigo de Portugal",
            "hackathon2": "está a festejar a sua 10ª Edição!",
            "hackathon1mobile": "O Hackathon mais antigo",
            "hackathon2mobile": "de Portugal está a festejar",
            "hackathon3mobile": "a sua 10ª Edição!",
            "titulo": "Estamos de volta!",
            "paragrafo1": "O ",
            "paragrafo2": "Núcleo de Estudantes de Informática",
            "paragrafo3": " e a ",
            "paragrafo4": "jeKnowledge",
            "paragrafo5": " voltaram a unir-se para mais uma épica edição do Shift APPens!",
            "paragrafo6": "Durante",
            "paragrafo7": " 48 horas",
            "paragrafo8": ", tu e os teus amigos poderão desenvolver projetos tecnológicos incríveis enquanto passam o melhor fim de semana da vossa vida!",
        },
        "EN": {
            "hackathon1": "The oldest Hackathon in Portugal",
            "hackathon2": "is celebrating its 10th Edition!",
            "hackathon1mobile": "The oldest Hackathon",
            "hackathon2mobile": "in Portugal is celebrating",
            "hackathon3mobile": "its 10th Edition!",
            "titulo": "We are back!",
            "paragrafo1": "The ",
            "paragrafo2": "NEI/AAC",
            "paragrafo3": " and ",
            "paragrafo4": "jeKnowledge",
            "paragrafo5": " have joined forces once again for another epic edition of Shift APPens!",
            "paragrafo6": "For ",
            "paragrafo7": "48 hours",
            "paragrafo8": ", you and your friends will be able to develop incredible technological projects while having the best weekend of your life!",
        }
    }

    const isMobile = useMediaQuery({ query: '(max-width: 750px)' })
   
    return (
        <div className="about-section-container">
            <div className="anniversary-box" ref={props.navDefaultColor}>
                {!isMobile ? (
                <h3> {aboutText[props.currentLanguage]["hackathon1"]}<br></br>{aboutText[props.currentLanguage]["hackathon2"]}</h3>
                ) : (
                <h3>{aboutText[props.currentLanguage]["hackathon1mobile"]}<br></br>{aboutText[props.currentLanguage]["hackathon2mobile"]}<br></br>  {aboutText[props.currentLanguage]["hackathon3mobile"]}</h3>
                )}
            </div>
            <div className="about-section-info">
            
                <h2>{aboutText[props.currentLanguage]["titulo"]}</h2>
                <div>
                    <p className="about-page-text">{aboutText[props.currentLanguage]["paragrafo1"]} <span className="bold-text">{aboutText[props.currentLanguage]["paragrafo2"]}</span>{aboutText[props.currentLanguage]["paragrafo3"]}<span className="bold-text">{aboutText[props.currentLanguage]["paragrafo4"]}</span> {aboutText[props.currentLanguage]["paragrafo5"]}</p>
                    <p className="about-page-text">{aboutText[props.currentLanguage]["paragrafo6"]}<span className="bold-text">{aboutText[props.currentLanguage]["paragrafo7"]}</span>{aboutText[props.currentLanguage]["paragrafo8"]}</p>
                </div>
                <div className="about-section-video-player" >
                    <video controls ref={videoRef}>
                        <source src={assets.shift_trailer} type="video/mp4" />
                    </video>
                </div>
            </div>
        </div>
        
    
    )
}

export default AboutSection