import  { useState, useEffect, useRef } from 'react'
import "./RegisterForms.css"
import { IoArrowForward } from "react-icons/io5";
import assets from "../../assets/index"
import { GrCircleInformation } from "react-icons/gr";
import { FaChevronDown } from "react-icons/fa";
import { TiTick } from "react-icons/ti";
import {CopyToClipboard} from 'react-copy-to-clipboard';
import { RxCopy } from "react-icons/rx";
import axios from 'axios'



async function refreshEarlybird(props, setFormsText) {
            
    
    try {
        let quantia = "20.00€"
        let registerType = "Normal"

        if ( (props.formData.occupation === "Student" || props.formData.occupation === "Estudante" ) && props.shiftType !== "academy") { // SE FOR ESTUDANTE
            
            const response = await axios.get('https://api.shiftappens.com/api/form/registrations')
            
            const number = parseInt(response.data.message)
            quantia = number < 20 ? "7.00€" : "8.50€";
            registerType = number < 20 ? "Early Bird" : "Normal";


            setFormsText({
                "PT": {
                    "earlyBird": registerType,
                    "quantia": "Quantia a pagar: " + quantia,
                    "dadosPagamento": "Dados de pagamento",
                    "nome": "Nome:",
                    
                    "adiciona": "Adiciona, por favor, o teu primeiro e último nome na descrição.",
                    "termoResponsabilidade": "Termo de responsabilidade para menores de idade*",
                    "enviaEmail": "Caso não autorizes ser fotografado(a) durante o evento, envia e-mail para:",
                    
                    "submeter": "Submeter",
                    "dadosSubmetidos": "Os dados submetidos serão apenas tratados pela equipa organizadora do Shift APPens e ",
                    "eliminados": "serão eliminados até 30 dias ",
                    "depois": "após o evento.",
                    "vegetariano": "És vegetariano(a)/vegan?",
                    "obrigatorio": "Obrigatório",
                    "invalido": "Email inválido",
                    "responsabilidade": "Termo de responsabilidade para menores de idade",
                    "campoObrigatorio": "Este campo é obrigatório",
                    "pagina": "Página",
                    "de": " de ",
                    "invalidoTelefone": "Número de telefone inválido",
                },
        
                "EN": {
                    "earlyBird": registerType,
                    "quantia": "Amount to pay: " + quantia,
                    "dadosPagamento": "Payment information",
                    "nome": "Name:",
                    "iban": "IBAN:",
                    "adiciona": "Please add your first and last name in the description.",
                    "termoResponsabilidade": "Responsibility term for minors*",
                    "enviaEmail": "If you do not authorize to be photographed during the event, send an email to:",
                    
                    "submeter": "Submit",
                    "dadosSubmetidos": "The submitted data will only be processed by the Shift APPens organizing team and",
                    "eliminados": "will be deleted up to 30 days",
                    "depois": "after the event.",
                    "vegetariano": "Are you vegetarian/vegan?",
                    "obrigatorio": "Mandatory",
                    "invalido": "Invalid email",
                    "responsabilidade": "Responsibility term for minors",
                    "campoObrigatorio": "This field is mandatory",
                    "pagina": "Page",
                    "de": " of ",
                    "invalidoTelefone": "Invalid phone number",
                }
        })
        
            }
        else {
            setFormsText({
                "PT": {
                    "earlyBird": registerType,
                    "quantia": "Quantia a pagar: " + quantia,
                    "dadosPagamento": "Dados de pagamento",
                    "nome": "Nome:",
                    
                    "adiciona": "Adiciona, por favor, o teu primeiro e último nome na descrição.",
                    "termoResponsabilidade": "Termo de responsabilidade para menores de idade*",
                    "enviaEmail": "Caso não autorizes ser fotografado(a) durante o evento, envia e-mail para:",
                    
                    "submeter": "Submeter",
                    "dadosSubmetidos": "Os dados submetidos serão apenas tratados pela equipa organizadora do Shift APPens e ",
                    "eliminados": "serão eliminados até 30 dias ",
                    "depois": "após o evento.",
                    "vegetariano": "És vegetariano(a)/vegan?",
                    "obrigatorio": "Obrigatório",
                    "invalido": "Email inválido",
                    "responsabilidade": "Termo de responsabilidade para menores de idade",
                    "campoObrigatorio": "Este campo é obrigatório",
                    "pagina": "Página",
                    "de": " de ",
                    "invalidoTelefone": "Número de telefone inválido",
                },
        
                "EN": {
                    "earlyBird": registerType,
                    "quantia": "Amount to pay: " + quantia,
                    "dadosPagamento": "Payment information",
                    "nome": "Name:",
                    "iban": "IBAN:",
                    "adiciona": "Please add your first and last name in the description.",
                    "termoResponsabilidade": "Responsibility term for minors*",
                    "enviaEmail": "If you do not authorize to be photographed during the event, send an email to:",
                    
                    "submeter": "Submit",
                    "dadosSubmetidos": "The submitted data will only be processed by the Shift APPens organizing team and",
                    "eliminados": "will be deleted up to 30 days",
                    "depois": "after the event.",
                    "vegetariano": "Are you vegetarian/vegan?",
                    "obrigatorio": "Mandatory",
                    "invalido": "Invalid email",
                    "responsabilidade": "Responsibility term for minors",
                    "campoObrigatorio": "This field is mandatory",
                    "pagina": "Page",
                    "de": " of ",
                    "invalidoTelefone": "Invalid phone number",
                }
        })

        }
    }
    catch (error) {
        console.log(error)
    }

}




const FormStructure = (props) => {
    
    const data = props.formStructureData[props.currentPage]
    
    const calculateStepSize = () => {
        const size = data.step * 20;

        return `${size}%`
    }


    const downloadFile = () => {
        const link = document.createElement('a');
        link.href = 'https://drive.google.com/file/d/1ub9vc-J9wybghZPBMpmpdepLVoN2qY-n/view';
        link.download = 'termo-responsabilidade-menores.pdf';
        link.click();
    }

    const missingFields = props.missingFields
    const colorStyle = data.palette.mainColor
    const miniColorStyle = data.palette.miniText
    const backgroundColorStyle = data.palette.backgroundColor

    const [hoveredButton, setHoveredButton] = useState(null);

  
    
    
    const [copiedToClipboard, setCopiedToClipboard] = useState(false);

    const handleCopyToClipboard = () => {
        setCopiedToClipboard(true);
        setTimeout(() => {
            setCopiedToClipboard(false);
        }, 5000); // Change 5000 to the desired duration in milliseconds (e.g., 5000 for 5 seconds)
    };

    const [formsText, setFormsText] = useState({
        "PT": {
            "earlyBird": "Early Bird",
            "quantia": "Quantia a pagar: 8.00€",
            "dadosPagamento": "Dados de pagamento",
            "nome": "Nome:",
            
            "adiciona": "Adiciona, por favor, o teu primeiro e último nome na descrição.",
            "termoResponsabilidade": "Termo de responsabilidade para menores de idade*",
            "enviaEmail": "Caso não autorizes ser fotografado(a) durante o evento, envia e-mail para:",
            
            "submeter": "Submeter",
            "dadosSubmetidos": "Os dados submetidos serão apenas tratados pela equipa organizadora do Shift APPens e ",
            "eliminados": "serão eliminados até 30 dias ",
            "depois": "após o evento.",
            "vegetariano": "És vegetariano(a)/vegan?",
            "obrigatorio": "Obrigatório",
            "invalido": "Email inválido",
            "responsabilidade": "Termo de responsabilidade para menores de idade",
            "campoObrigatorio": "Este campo é obrigatório",
            "pagina": "Página",
            "de": " de ",
            "invalidoTelefone": "Número de telefone inválido",
        },

        "EN": {
            "earlyBird": "Early Bird",
            "quantia": "Amount to pay: 8.00€",
            "dadosPagamento": "Payment information",
            "nome": "Name:",
            "iban": "IBAN:",
            "adiciona": "Please add your first and last name in the description.",
            "termoResponsabilidade": "Responsibility term for minors*",
            "enviaEmail": "If you do not authorize to be photographed during the event, send an email to:",
            
            "submeter": "Submit",
            "dadosSubmetidos": "The submitted data will only be processed by the Shift APPens organizing team and",
            "eliminados": "will be deleted up to 30 days",
            "depois": "after the event.",
            "vegetariano": "Are you vegetarian/vegan?",
            "obrigatorio": "Mandatory",
            "invalido": "Invalid email",
            "responsabilidade": "Responsibility term for minors",
            "campoObrigatorio": "This field is mandatory",
            "pagina": "Page",
            "de": " of ",
            "invalidoTelefone": "Invalid phone number",
        }
    })


    


    useEffect(() => {
     
        refreshEarlybird(props, setFormsText )
    }, [props.currentPage, props.formData.occupation, props.shiftType]);




    const [hoveredCopyToClipboard, setHoveredCopyToClipboard] = useState(false);

    const hoverCopyToClipboard = () => {
        setHoveredCopyToClipboard(!hoveredCopyToClipboard);
    }



    return (
        <div className="forms-structure">
            <div className="forms-structure-header">
                <span style={{color: colorStyle}}>{data.step}</span>
                <IoArrowForward style={{color: colorStyle}}/>
                <h4 style={{color: colorStyle}}>{data.name}</h4>
            </div>
            {data.step === 5 ? (
                <div className="forms-payment-info">
                    <div >
                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <g id="Icon">
                            <path d="M14.9208 7.21688L8.63636 14.0726L5.0792 10.1921L6.41948 8.9635L8.63636 11.3819L13.5805 5.98829L14.9208 7.21688Z" fill={colorStyle}/>
                            <path fillRule="evenodd" clipRule="evenodd" d="M0 10C0 4.47715 4.47715 0 10 0C15.5228 0 20 4.47715 20 10C20 15.5228 15.5228 20 10 20C4.47715 20 0 15.5228 0 10ZM10 1.81818C5.48131 1.81818 1.81818 5.48131 1.81818 10C1.81818 14.5187 5.48131 18.1818 10 18.1818C14.5187 18.1818 18.1818 14.5187 18.1818 10C18.1818 5.48131 14.5187 1.81818 10 1.81818Z" fill={colorStyle}/>
                        </g>
                    </svg>
                    <h5 style={{color: colorStyle}}>{formsText[props.currentLanguage].earlyBird}</h5>
                    </div>
                    <div className="payment-info-container">
                        <h4 style={{color: colorStyle}}>{formsText[props.currentLanguage].quantia} </h4>
                        <h6 className="payment-title" style={{color: colorStyle}}>{formsText[props.currentLanguage].dadosPagamento}</h6>
                        <div className="payment-info-jek">
                            <p style={{color: colorStyle}} className="payment-title">{formsText[props.currentLanguage].nome}</p>
                            <p style={{color: colorStyle}} className="payment-info">jeKnowledge Associação</p>
                        </div>
                        <div className="payment-info-jek">
                            <p  style={{color: colorStyle}}className="payment-title">IBAN:</p>
                            <p style={{color: colorStyle}} className="payment-info">PT50 0045 3030 4022 3802 8161 8 (Crédito Agrícola) 
                            <CopyToClipboard text="PT50 0045 3030 4022 3802 8161 8">
                                <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 28 28" fill="none" 
                                    onClick={handleCopyToClipboard}
                                    onMouseEnter={hoverCopyToClipboard}
                                    onMouseLeave={hoverCopyToClipboard} >
                                <path d="M8.16669 11.2782C8.16669 10.453 8.4945 9.6616 9.07802 9.07809C9.66154 8.49457 10.453 8.16675 11.2782 8.16675H21.3885C21.7971 8.16675 22.2017 8.24723 22.5792 8.4036C22.9567 8.55996 23.2998 8.78916 23.5887 9.07809C23.8776 9.36701 24.1068 9.71002 24.2632 10.0875C24.4195 10.465 24.5 10.8696 24.5 11.2782V21.3886C24.5 21.7972 24.4195 22.2018 24.2632 22.5793C24.1068 22.9568 23.8776 23.2998 23.5887 23.5887C23.2998 23.8777 22.9567 24.1069 22.5792 24.2632C22.2017 24.4196 21.7971 24.5001 21.3885 24.5001H11.2782C10.8696 24.5001 10.465 24.4196 10.0875 24.2632C9.70996 24.1069 9.36695 23.8777 9.07802 23.5887C8.78909 23.2998 8.5599 22.9568 8.40354 22.5793C8.24717 22.2018 8.16669 21.7972 8.16669 21.3886V11.2782Z" stroke="#E3314E" strokeWidth="2.33333" strokeLinecap="round" strokeLinejoin="round"/>
                                <path d="M4.68067 19.5265C4.3229 19.3225 4.02532 19.0277 3.818 18.6719C3.61069 18.3161 3.50099 17.9118 3.5 17.5V5.83333C3.5 4.55 4.55 3.5 5.83333 3.5H17.5C18.375 3.5 18.851 3.94917 19.25 4.66667" stroke="#E3314E" strokeWidth="2.33333" strokeLinecap="round" strokeLinejoin="round"/>
                                <rect x="9" y="9" width="15" height="15" fill={copiedToClipboard || hoveredCopyToClipboard ? '#E3314E' : ''}/>
                                </svg>
                            </CopyToClipboard></p>
                        </div>
                    </div>
                    <span className="extra-payment-info"style={{color: colorStyle}} >{formsText[props.currentLanguage].adiciona}</span>
                </div>
            ) : null}

            {data.dropdownInputs ? (
                <div className="forms-dropdown-inputs">
                    {data.dropdownInputs.map((input) => (
                        <DropdownInput key={input.label} label={input.label} options={input.options} placeholder={input.placeholder} colorPalette={data.palette}  value={input.value}  formData={props.formData} missingFields={missingFields} handleInputChange={props.handleInputChange}/>
                    ))}
                </div>
            ) : null}
            {data.inputs ? ( 
            <div className="forms-normal-inputs">
                {data.inputs.map((input) => (
                    <FormsInput key={input.label}  step={data.step} validEmail={props.validEmail} label={input.label}  placeholder={input.placeholder} type={input.type} value={input.value} colorPalette={data.palette} size="full"  handleInputChange={props.handleInputChange} formData={props.formData} missingFields={missingFields} currentLanguage={props.currentLanguage} formsText={formsText}/>
                ))}
            </div>
            ) : null}
            {data.miniInputs ? (
            <div className="forms-mini-inputs">
                {data.miniInputs.map((input) => (
                    <FormsInput key={input.label} step={data.step} miniInput={true} label={input.label} placeholder={input.placeholder} type={input.type} value={input.value}  colorPalette={data.palette} size="half"  handleInputChange={props.handleInputChange} formData={props.formData} missingFields={missingFields} currentLanguage={props.currentLanguage} formsText={formsText} validPhone={props.validPhone}/>
                ))}

            </div>
            ): null}
            {(props.shiftType==="academy" || props.formData?.age < 18) && data.step === 4 ? (
                <div className="academy-forms-custom-inputs">
                    <label className={`${props.missingFields.includes(props.value) ? 'forms-missing-field-label' : ''}`} style={{color: colorStyle}}>{formsText[props.currentLanguage].termoResponsabilidade}</label>
                    <div className="academy-form-buttons">
                        
                        <a 
                            href='https://drive.google.com/file/d/1ub9vc-J9wybghZPBMpmpdepLVoN2qY-n/view?usp=sharing'
                            target="_blank"
                            rel="noopener noreferrer"
                            style={{textDecoration: 'none', width:"45%"}}
                            ><button 
                                style={{borderColor: colorStyle, color: hoveredButton === "download" ?  'white' : miniColorStyle, backgroundColor: hoveredButton === "download" ? colorStyle : ''}} 
                                type="file" 
                                onMouseEnter={() =>setHoveredButton("download")}
                                onMouseLeave={() => setHoveredButton(null)}
                            >
                                Download 
                                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                    <path d="M10.8334 7.5V11.3215L11.6667 10.4882L12.8452 11.6667L10 14.5118L7.15486 11.6667L8.33337 10.4882L9.16671 11.3215V7.5H10.8334Z" fill={hoveredButton==="download" ?  'white' : colorStyle}/>
                                    <path fillRule="evenodd" clipRule="evenodd" d="M0.833374 2.5H7.75235L10.2524 4.16667H19.1667V17.5H0.833374V2.5ZM2.50004 4.16667V15.8333H17.5V5.83333H9.74773L7.24773 4.16667H2.50004Z" fill={hoveredButton==="download" ?  'white' : colorStyle}/>
                                </svg>
                                
                            </button>
                        </a>
                        <FormsInput type="file" value="responsibilityTerm" label="responsibility Term" handleInputChange={props.handleInputChange} formData={props.formData} colorPalette={data.palette} missingFields={missingFields} placeholder="Upload" miniInput={true} currentLanguage={props.currentLanguage} formsText={formsText}/>
                    </div> 

                    {props.missingFields.includes(props.value) ? (
                        <span className="forms-missing-field-message"> <GrCircleInformation /> {formsText[props.currentLanguage].campoObrigatorio}</span>
                    ) : null}

                </div>
            ) : null}
            
            

            { data.checkboxInputs  ? (
                <div className="forms-checkbox-inputs-container ">
                    {data.step === 3 ? (
                    <label className={`${props.missingFields.includes(props.value) ? 'forms-missing-field-label' : ''}`} style={{color: colorStyle}}>{formsText[props.currentLanguage].vegetariano}</label>
                    ) : data.step === 4 ? (
                        <p style={{color: colorStyle}} className="pre-checkbox-text">{formsText[props.currentLanguage].enviaEmail}<br></br> <span className="underlined-text">participantes@shiftappens.com</span></p>
                    ) : null}
                    <div className="forms-checkbox-inputs">
                        {data.checkboxInputs.map((input) => (
                            <CheckBoxInput key={input.label} label={input.label} colorPalette={data.palette} value={input.value} option={input.option} handleInputChange={props.handleInputChange} formData={props.formData} missingFields={props.missingFields} />
                        ))}
                    </div>
                </div>
            ) : null
            }
            {data.step === 5 ? (
                <div className="forms-submit">
                    <span style={{color: colorStyle}}>{formsText[props.currentLanguage].dadosSubmetidos}<span className="bold-text">{formsText[props.currentLanguage].eliminados}</span> {formsText[props.currentLanguage].depois}</span>
                    <button style={{backgroundColor: colorStyle}} onClick={props.handleFormsSubmit} >{formsText[props.currentLanguage].submeter}</button>
                </div>
            ) : null}
            <div className="forms-step">
                <p className="forms-step-page" style={{color: miniColorStyle}}>{formsText[props.currentLanguage].pagina} {data.step} {formsText[props.currentLanguage].de} 5</p>
                <div style={{backgroundColor: backgroundColorStyle}}>
                    <span style={{ width: calculateStepSize(), backgroundColor: colorStyle }} ></span>
                </div>
            </div>

        </div>

    )
}

const FormsInput = (props) => {


    const [hoveredButton, setHoveredButton] = useState(null);
    const handleOpenFileExplorer = () => {
        document.getElementById(props.label).click();
    };
    const colorPalette = props.colorPalette

    function validateEmail(e) {
        var email = e.target;
    
    
        // HTML5 email validation
        if (email.validity.valid) {
            props.handleInputChange(props.value, e);
            alert("Valid email address.");
            // You can proceed with further actions or submit the form
        } else {
            alert("Invalid email address. Please enter a valid email.");
        }
    }

    return (
        
            <div className={`forms-input ${props.miniInput ? 'forms-mini-input' : '' }`}>
            {props.value !=="responsibilityTerm" ? (
                <label  className={`${props.missingFields.includes(props.value) || (props.value === "email" && props.validEmail === false) || (props.value === "phone" && props.validPhone === false ) ? 'forms-missing-field-label' : ''}`} style={{color: colorPalette.mainColor}} >{props.label}</label>
            )   : null}
            {props.type === "file" ? (
                    <div 
                        className={`forms-custom-file-input ${props.missingFields.includes(props.value) || (props.value === "email" && props.validEmail === false) ? 'forms-missing-field-input' : ''}`} 
                        style={{color: hoveredButton === "upload" ? 'white' : colorPalette.miniText, borderColor: colorPalette.mainColor, backgroundColor: hoveredButton === "upload" ? colorPalette.mainColor : ''}} 
                        onMouseEnter={() => setHoveredButton("upload")}
                        onMouseLeave={() => setHoveredButton(null)}
                        onClick={() => handleOpenFileExplorer()} 
                    >
                        
                        <span 
                                className={` ${props.missingFields.includes(props.value) ? 'forms-missing-field-label' : ''}`} 
                                style={{ color: props.formData[props.value] !== "" ? colorPalette.valueColor : '' }}
                            >
                                {props.formData[props.value] ? props.formData[props.value].name : props.placeholder}
                        </span>
                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none" >
                            <path d="M9.99967 8.15482L12.8449 11L11.6663 12.1785L10.833 11.3452V15.1667H9.16634V11.3452L8.33301 12.1785L7.1545 11L9.99967 8.15482Z" fill={hoveredButton==="upload" ?  'white' : colorPalette.mainColor} className={` ${props.missingFields.includes(props.value) ? 'forms-missing-field-icon' : ''}`}/>
                            <path fillRule="evenodd" clipRule="evenodd" d="M0.833008 3.5H7.75199L10.252 5.16667H19.1663V18.5H0.833008V3.5ZM2.49967 5.16667V16.8333H17.4997V6.83333H9.74736L7.24736 5.16667H2.49967Z" fill={hoveredButton==="upload" ?  'white' : colorPalette.mainColor} className={` ${props.missingFields.includes(props.value) ? 'forms-missing-field-icon' : ''}`}/>
                        </svg>
                        <input
                            id={props.label}
                            type="file"
                            style={{ display: 'none' }}
                            onChange={(e) => props.handleInputChange(props.value, e)}
                        />
                    </div>
                ) : (
                    <input style={{color: colorPalette.valueColor, borderColor: colorPalette.mainColor}} 
                        className={`${props.step === 1 ? 'orange-placeholder' : props.step === 2 ? 'pink-placeholder' : props.step ===3 ? 'blue-placeholder' : props.step === 4 ? 'green-placeholder' : props.step ===5 ? 'red-placeholder' : '' } ${props.missingFields.includes(props.value) || (props.value === "email" && props.validEmail === false) || (props.value === "phone" && props.validPhone === false) ? 'forms-missing-field-input' : ''}`} 
                        type={props.type} 
                        placeholder={props.placeholder}
                        value={props.formData.value}
                        onChange={(e) =>props.handleInputChange(props.value, e)}/>
                ) }
            {props.missingFields.includes(props.value) ? (
                <span className="forms-missing-field-message"> <GrCircleInformation /> {props.formsText[props.currentLanguage].campoObrigatorio}</span>
            ) : null}
            {props.value === "email" && props.validEmail === false ? (
                <span className="forms-missing-field-message"> <GrCircleInformation />{props.formsText[props.currentLanguage].invalido}</span>
            ) : null}
            {props.value === "phone" && props.validPhone === false ? (
                <span className="forms-missing-field-message"> <GrCircleInformation />{props.formsText[props.currentLanguage].invalidoTelefone}</span>
            ) : null}
        </div>
        ) 
}


const CheckBoxInput = (props) => {

    
    const colorPalette = props.colorPalette

    const handleCheckboxChange = () => {
        if (props.formData[props.value] === props.option) {
            props.handleInputChange(props.value, { target: { value: "" } })
        } else {

            props.handleInputChange(props.value, { target: { value: props.option } })
        }

    }

    const labelParts = props.label.split("termos e condições")
    

    return (
        <div className="forms-checkbox-input">
            <div 
                className={`custom-checkbox ${props?.missingFields.includes(props.value) ? 'forms-missing-field-input' : ''}`} 
                style={{
                    borderColor: colorPalette.mainColor,
                    backgroundColor: props.formData[props.value] === props.option ? colorPalette.mainColor : null
                }}
                onClick={() => handleCheckboxChange()}
            >
                {props.formData[props.value] === props.option ? <TiTick style={{color: 'white'}}/> : null}
            </div>
            <label className={` ${props.missingFields.includes(props.value) ? 'forms-missing-field-label' : ''}`}  style={{color: colorPalette.mainColor}}>
                
                {labelParts.length === 2 ? (
                    <>
                        {labelParts[0]} 
                        <a 
                            target="_blank" 
                            href="https://drive.google.com/file/d/188ZHsRHhCE5TQMLfzMwE54rbe4C4og6S/view?usp=sharing" 
                            style={{ textDecoration: 'underline' }} rel="noreferrer">termos e condições
                        </a> 
                            
                        {labelParts[1]}
                    </>
                ) : (
                    props.label
                )}</label>
        </div>
    )
}


const DropdownInput = (props) => {
    const dropdownRef = useRef(null);
    
 
    const [openDropdown, setOpenDropdown] = useState(false);
    const colorPalette = props.colorPalette
    const [selectedOption, setSelectedOption] = useState(props.formData[props.value]);
    const [hoveredOption, setHoveredOption] = useState(null);

    

    const handleOpenDropdown = () => {
        setOpenDropdown(!openDropdown)
    }
    
    const handleOptionClick = (option) => {
        setSelectedOption(option);
        props.handleInputChange(props.value, { target: { value: option } });
        setOpenDropdown(false);
        
    };

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setOpenDropdown(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    return (
        <div className="forms-input dropdown-custom-input" ref={dropdownRef}>
            <label className={`${props.missingFields.includes(props.value) ? 'forms-missing-field-label' : ''}`} style={{color: colorPalette.mainColor}}>{props.label}</label>
            <div className={`forms-custom-file-input ${openDropdown ? 'open-custom-dropdown' : ''} ${props.missingFields.includes(props.value) ? 'forms-missing-field-input' : ''}`} style={{borderColor: colorPalette.mainColor, backgroundColor: colorPalette.pageBackground}} onClick={handleOpenDropdown}>
                <span style={{color: props.formData[props.value] === "" ? colorPalette.miniText : colorPalette.valueColor}}  className={`${props.missingFields.includes(props.value) ? 'forms-missing-field-label' : ''}`}>{props.formData[props.value] === "" ? props.placeholder : props.formData[props.value]}</span>
                <FaChevronDown style={{color: colorPalette.miniText}}  className={`${props.missingFields.includes(props.value) ? 'forms-missing-field-icon' : ''}`}/>

            </div>
            {openDropdown ? (
                <div className="dropdown-options" style={{borderColor: colorPalette.mainColor, backgroundColor: colorPalette.pageBackground }}>
                    {props.options.map((option) => (
                        <div 
                            onClick={() => handleOptionClick(option)} 
                            key={option} 
                            style={{ borderColor: colorPalette.mainColor, backgroundColor: props.formData[props.value] === option ? colorPalette.mainColor : hoveredOption === option ? colorPalette.backgroundColor : ''}}
                            onMouseEnter={() => setHoveredOption(option)}
                            onMouseLeave={() => setHoveredOption(null)}

                        >
                            <span style={{color: props.formData[props.value] === option ? 'white' : colorPalette.mainColor}} >{option}</span>
                        </div>
                    ))}
                </div>
            ) : null}
            

            
        </div>
    )

}


export default FormStructure    