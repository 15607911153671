import {  useState} from 'react'
import './CarouselSection.css'
import assets from "../../assets/index"
import { Carousel } from 'react-responsive-carousel';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { useMediaQuery } from 'react-responsive';


const CarouselItem = ({ header, text }) => (
    <div className="carousel-item">
      <h2>{header}</h2>
      <p>{text}</p>
    </div>
  );
  
  const CarouselSection = (props) => {
    const [carouselIndex , setCarouselIndex] = useState(0)

    const isTablet = useMediaQuery({ query: '(max-width: 1050px)' })
    
    
    
    const [items, setItems] = useState([

      { header: "1193", text: { PT: "Participantes", EN: "Participants" } },
      { header: "+200", text: { PT: "Projetos Criados", EN: "Projects Created" } },
      { header: "10", text: { PT: "Edições", EN: "Editions" } },
      { header: "1193", text: { PT: "Participantes", EN: "Participants" } },
      { header: "+200", text: { PT: "Projetos Criados", EN: "Projects Created" } },
      { header: "10", text: { PT: "Edições", EN: "Editions" } },
      { header: "1193", text: { PT: "Participantes", EN: "Participants" } },
      { header: "+200", text: { PT: "Projetos Criados", EN: "Projects Created" } },
      { header: "10", text: { PT: "Edições", EN: "Editions" } },
      { header: "1193", text: { PT: "Participantes", EN: "Participants" } },
      { header: "+200", text: { PT: "Projetos Criados", EN: "Projects Created" } },
      { header: "10", text: { PT: "Edições", EN: "Editions" } },
      { header: "1193", text: { PT: "Participantes", EN: "Participants" } },
      { header: "+200", text: { PT: "Projetos Criados", EN: "Projects Created" } },
      { header: "10", text: { PT: "Edições", EN: "Editions" } },
      { header: "1193", text: { PT: "Participantes", EN: "Participants" } },
      { header: "+200", text: { PT: "Projetos Criados", EN: "Projects Created" } },
      { header: "10", text: { PT: "Edições", EN: "Editions" } },
      { header: "1193", text: { PT: "Participantes", EN: "Participants" } },
      { header: "+200", text: { PT: "Projetos Criados", EN: "Projects Created" } },
      { header: "10", text: { PT: "Edições", EN: "Editions" } },
      { header: "1193", text: { PT: "Participantes", EN: "Participants" } },
      { header: "+200", text: { PT: "Projetos Criados", EN: "Projects Created" } },
      { header: "10", text: { PT: "Edições", EN: "Editions" } },
      { header: "1193", text: { PT: "Participantes", EN: "Participants" } },
      { header: "+200", text: { PT: "Projetos Criados", EN: "Projects Created" } },
      { header: "10", text: { PT: "Edições", EN: "Editions" } },
      { header: "1193", text: { PT: "Participantes", EN: "Participants" } },
      { header: "+200", text: { PT: "Projetos Criados", EN: "Projects Created" } },
      { header: "10", text: { PT: "Edições", EN: "Editions" } },
      { header: "1193", text: { PT: "Participantes", EN: "Participants" } },
      { header: "+200", text: { PT: "Projetos Criados", EN: "Projects Created" } },
      { header: "10", text: { PT: "Edições", EN: "Editions" } }
    ]);
    const middleIndex = Math.floor(items.length / 2)

    return (
      <div className="carousel-section-container">
        {!isTablet ? (
        <Carousel
          showThumbs={false}
          showStatus={false}
          showIndicators={false}
          autoPlay={true}
          infiniteLoop={true}
          interval={10000}
          transitionTime={1000}
          swipeable={true}
          centerMode={true}
          centerSlidePercentage={40}
          showArrows={true}
          startIndex={middleIndex}
          emulateTouch={true}
          selectedItem={middleIndex}
          renderArrowPrev={(onClickHandler, hasPrev, label) =>
            hasPrev && (
              <button
                id="button"
                onClick={onClickHandler}
                className="carrousel-arrow-prev"
              >
                <img src={assets.carousel_arrow} alt="Previous" />
              </button>
            )
          }
          renderArrowNext={(onClickHandler, hasNext, label) =>
            hasNext && (
              <button
                id="button"
                onClick={onClickHandler}
                className="carrousel-arrow-next"
              >
                <img src={assets.carousel_arrow} alt="Next" />
              </button>
            )
          }
        >
          {items.map((item, index) => (
            <CarouselItem key={index} header={item.header} text={item.text[props.currentLanguage]} />
            ))}
        
        </Carousel>
        ) : (
          <Carousel
          showThumbs={false}
          showStatus={false}
          showIndicators={false}
          autoPlay={true}
          infiniteLoop={true}
          interval={10000}
          transitionTime={1000}
          swipeable={true}
          centerMode={true}
          centerSlidePercentage={100}
          showArrows={true}
          startIndex={middleIndex}
          emulateTouch={true}
          
          renderArrowPrev={(onClickHandler, hasPrev, label) =>
            hasPrev && (
              <button
                id="button"
                onClick={onClickHandler}
                className="carrousel-arrow-prev"
              >
                <img src={assets.carousel_arrow} alt="Previous" />
              </button>
            )
          }
          renderArrowNext={(onClickHandler, hasNext, label) =>
            hasNext && (
              <button
                id="button"
                onClick={onClickHandler}
                className="carrousel-arrow-next"
              >
                <img src={assets.carousel_arrow} alt="Next" />
              </button>
            )
          }
        >
          {items.map((item, index) => (
            <CarouselItem key={index} header={item.header} text={item.text[props.currentLanguage]} />
            ))}
        
        </Carousel>
        )}
      </div>

    );
  };
  
  export default CarouselSection;