
import "./HomeSection.css"
import assets from "../../assets/index"
import LanguageChanger from '../../components/LanguageChanger/LanguageChanger'
import { useMediaQuery } from 'react-responsive';

const HomeSection = (props) => {

    const isMobile = useMediaQuery({ query: '(max-width: 600px)' })

    const scrollToAboutSection = () => {
        const aboutSection = document.getElementById('landing-about');
        aboutSection.scrollIntoView({ behavior: 'smooth' });
    };

    const homeText = {
        "PT": {
            "edicao": "10ª Edição",
        },
        "EN": {
            "edicao": "10th Edition",
        }
    };
    
    return (
        <div className="home-section-container">
            <div className="home-language-changer">
                <LanguageChanger currentLanguage={props.currentLanguage} setCurrentLanguage={props.setCurrentLanguage}/>
            </div>
            <div className="home-logo-container">
                <h1>{homeText[props.currentLanguage]["edicao"]} </h1>
                <img src={assets.main_logo}></img>
            </div>
            <div className="swipe-up" onClick={scrollToAboutSection}>
                <span><img src={assets.swipe_up}></img></span>
                <p >Swipe</p>
            </div>
        </div>
    )
}

export default HomeSection