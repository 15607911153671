import react, { useState } from "react";
import "./AboutPage.css"
import Navbar from "../../components/Navbar/Navbar";
import assets from "../../assets/index"
import Footer from "../../components/Footer/Footer";
import iconAssets from '../../assets/AboutPageSvgs/index'
import { useMediaQuery } from 'react-responsive'
import { AboutPageCards, FAQPageCards }  from "../../components/Cards/Cards";


const AboutPage = (props) => {
    console.log(props.currentLanguage)

    const cardPT = [ 
        {
            image: assets.jek_main_logo,
            title: "jeKnowledge",
            miniTitle: "jeKnowledge",
            text1Intro: "A ",
            text1Bold: "jeKnowledge",
            text1:" é a Júnior Empresa da FCTUC. Fundada em 2008, com o intuito de proporcionar aos alunos a oportunidade de aplicar os seus conhecimentos académicos, a “jeK”, como é vulgarmente conhecida, conta com 65 membros, todos estes estudantes das mais diversas engenharias e de design. ",
            text2: "Esta empresa procura aproximar os estudantes do mercado de trabalho, proporcionando oportunidades de trabalhar em equipas multidisciplinares, bem como de desenvolver novas competências aplicáveis em situações reais." 
        },
        {
            image: assets.nei_main_logo,
            title: "Núcleo de Estudantes de Informática da Associação Académica de Coimbra",
            miniTitle: "NEI",
            text1Intro: "O ",
            text1Bold: "NEI/AAC",
            text1: " é um órgão integrante da Associação Académica de Coimbra que tem o propósito de representar os estudantes de Engenharia Informática, Design e Multimédia e Engenharia e Ciência de Dados da Universidade de Coimbra.",
            text2: "Com este objetivo em vista, o NEI organiza regularmente atividades culturais, desportivas, recreativas e formativas, divulga e presta apoio na organização de outros eventos e promove diferentes espaços de uso livre, sempre com o intuito de enriquecer a experiência universitária dos seus estudantes."
            
        }   
    ]

    const cardEN = [
        {
            image: assets.jek_main_logo,
            title: "jeKnowledge",
            miniTitle: "jeKnowledge",
            text1Intro: "The ",
            text1Bold: "jeKnowledge",
            text1:' is the Junior Enterprise of FCTUC (Faculty of Sciences and Technology of the University of Coimbra). Established in 2008, its primary objective is to provide students with the opportunity to apply their academic knowledge. Commonly known as "jeK", it consists of 55 members, all students from various engineering and design fields.',
            text2: " This company seeks to bridge the gap between students and the job market by offering opportunities to work in multidisciplinary teams and develop new skills applicable in real-world situations."
        },
        {
            image: assets.nei_main_logo,
            title: "Núcleo de Estudantes de Informática da Associação Académica de Coimbra",
            miniTitle: "NEI",
            text1Intro: "The ",
            text1Bold: "NEI/AAC",
            text1: " is an integral body of the Associação Académica de Coimbra with the purpose of representing students from Informatics Engineering, Design and Multimedia, and Data Science Engineering Studying at the University of Coimbra. With this goal in mind, NEI regularly organizes cultural, sports, recreational, and educational activities",
            text2: "It also disseminates and provides support in the organization of other events while promoting various open-use spaces, all with the aim of enriching the university experience for its students.",
        }
    ]

    const cards = props.currentLanguage === "PT" ? cardPT : cardEN;


    const isMobile = useMediaQuery({ query: '(max-width: 750px)' })


  

    const aboutPageText = {
        "PT": {
            "sobreTitle": "Sobre",
            "organizacao": "Organização",
            "throwback": "Quem não gosta de um bom throwback? Este botão funciona como máquina do tempo, ",
            "clica": "clica ",
            "e": "e ",
            "veras": "verás como foi incrível a última edição do Shift APPens.",
            "website": "Website Edição 2023",
            "epica": "Se a última edição foi incrível, a deste ano será épica!"
        },
        "EN": {
            "sobreTitle": "About",
            "organizacao": "Organization",
            "throwback": "Who doesn't love a good throwback? This button works as a time machine, ",
            "clica": "click ",
            "e": "and ",
            "veras": "witness how amazing the last edition of Shift APPens was.",
            "website": "Website Edition 2023",
            "epica": "If the last edition was amazing, this year's will be epic!"

        }
    }

    return (
        <div className="about-page">
            <section className="landing-navbar">
                <Navbar currentLanguage={props.currentLanguage} NavCarouselColorView={true} toggleMenu={props.toggleMenu} openedMenu={props.openedMenu} isMobile={props.navbarIsMobile}/>
            </section>
            <div className="about-page-container">
                <h1>{aboutPageText[props.currentLanguage]["sobreTitle"]}</h1>
                <h3>{aboutPageText[props.currentLanguage]["organizacao"]}</h3>
                <div className="about-page-card-container">
                    {cards.map((card, index) => {
                        return (
                            <AboutPageCards 
                                key={index}
                                index={index}
                                image={card.image}
                                title={card.title}
                                miniTitle={card.miniTitle}
                                text1Intro={card.text1Intro}
                                text={card.text1}
                                text2={card.text2}
                                isMobile={isMobile}
                                text1Bold={card.text1Bold}
                            />
                        )
                    })}
                </div>
                <div className="previous-website-icons-container">
                    <div></div>
                    <div className="about-page-previous-website">
                        <div>
                            <p> {aboutPageText[props.currentLanguage]["throwback"]} 
                            <span className="bold-text">{aboutPageText[props.currentLanguage]["clica"]}</span> 
                            {aboutPageText[props.currentLanguage]["e"]} 
                            <span className="bold-texdt">{aboutPageText[props.currentLanguage]["veras"]}</span>
                            </p>
                        </div>
                        <button><a href="https://2023.shiftappens.com/" target="_blank">{aboutPageText[props.currentLanguage]["website"]}</a></button>
                        <div>
                            <p>
                                {aboutPageText[props.currentLanguage]["epica"]}
                            </p>
                        </div>
                    </div>
                    <div className="previous-website-icons">
                        {!isMobile ? <img src={iconAssets.about_page_arrow}></img> : null}
                        <img src={iconAssets.about_page_icon6}></img>
                    </div>
                </div>
                
            </div>
            <div className="about-page-icons-container">
                    <img src={iconAssets.about_page_arrow}></img>
                    <img src={iconAssets.about_page_icon1}></img>
                    <img src={iconAssets.about_page_icon2}></img>
                    <img src={iconAssets.about_page_icon3}></img>
                    <img src={iconAssets.about_page_arrow} ></img>
                    <img src={iconAssets.about_page_icon4}></img>
                    <img src={iconAssets.about_page_icon5}></img>
            </div>
            <section><Footer currentLanguage={props.currentLanguage} /></section>
            
        </div>

    )

}






export default AboutPage  ;