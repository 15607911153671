
import "./ScheduleSection.css"
import assets from "../../assets/index"
import { useMediaQuery } from 'react-responsive';

const ScheduleSection = (props) => {

    const isTablet = useMediaQuery({ query: '(max-width: 950px)' })

    const importAll = (context) => context.keys().map(context);
    const images = importAll(require.context('../../assets/DaysOfTheWeek', false, /\.(png|jpe?g|svg)$/)).sort((a, b) => {
        // Extract the numeric part from the file names and compare
        const numA = parseInt(a.match(/\d+/)[0], 10);
        const numB = parseInt(b.match(/\d+/)[0], 10);
        return numA - numB;
    });

    const mobileImages = importAll(require.context('../../assets/MobileDaysOfTheWeek', false, /\.(png|jpe?g|svg)$/)).sort((a, b) => {
        const numA = parseInt(a.match(/\d+/)[0], 10);
        const numB = parseInt(b.match(/\d+/)[0], 10);
        return numA - numB;
    });


    const daysOfTheWeek = {
        PT: ["Seg", "Ter", "Qua", "Qui", "Sex", "Sáb", "Dom"],
        EN: ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"]
      };
      
      const mobileDays = {
        PT: ["Sex", "Sáb", "Dom"],
        EN: ["Fri", "Sat", "Sun"]
      };
   
    const scheduleText = {
        "PT": {
            "dias": "19 a 21 de abril",
            "local": "Pavilhão C. F. União de Coimbra"
        },
        "EN": {
            "dias": "19th to 21st of April",
            "local": "Pavilhão C. F. União de Coimbra"
        }
    }
    
    return (
        <div className="schedule-section-container">
            
            <div className="schedule-section-headers">
                <h3>{scheduleText[props.currentLanguage].dias}</h3>
                <h2>{scheduleText[props.currentLanguage].local}</h2>
            </div>
            <div className="schedule-grid" >
                
                <div className={`${isTablet ? 'mobile-schedule-grid-days' : 'schedule-grid-days '}`}>
                   {!isTablet ? (
                    
                    daysOfTheWeek[props.currentLanguage].map((day) => {
                        return (
                            <div className="schedule-grid-day" key={day}>
                                <h6>{day}</h6>
                            </div>  
                        )   
                    })
                   ) : (
                    
                    mobileDays[props.currentLanguage].map((day) => {
                        return (
                            <div className="schedule-grid-day" key={day}>
                                <h6>{day}</h6>
                            </div>  
                        )   
                    })
                
                ) }
                </div>
                <div className={`${isTablet ? 'mobile-schedule-grid-images' : 'schedule-grid-images '}`}>
                    {!isTablet ? (
                    
                        images.map((image) => {
                            return (
                                <div className="schedule-grid-image" key={image}>
                                    <img src={image}></img>
                                </div>
                            )
                        })
                    
                    ) : (
                        mobileImages.map((image) => {
                            return (
                                <div className="schedule-grid-image" key={image}>
                                    <img src={image}></img>
                                </div>
                            )
                        }
                        ))
                    }
                </div>
            </div>

        </div>
    )
}

export default ScheduleSection