
import HomeSection from '../HomeSection/HomeSection'
import "./LandingPage.css"
import Navbar from '../../components/Navbar/Navbar'
import TransitionArrow from '../../components/TransitionArrow/TransitionArrow'
import AboutSection from '../AboutSection/AboutSection'
import Footer from '../../components/Footer/Footer'
import ScheduleSection from '../ScheduleSection/ScheduleSection'
import CarouselSection from '../CarouselSection/CarouselSection'
import SectionChanger from '../../components/SectionChanger/SectionChanger'
import {useInView} from "react-intersection-observer";
import PartnersSection from '../PartnersSection/PartnersSection'
import { useMediaQuery } from 'react-responsive';
import { useState } from 'react';

const LandingPage = ({currentLanguage, setCurrentLanguage, navbarIsMobile, openedMenu, toggleMenu }) => {
    
    const isTablet = useMediaQuery({ query: '(max-width: 1050px)' })
    const isMobile = useMediaQuery({ query: '(max-width: 650px)' })
    const isMobileHeight = useMediaQuery({ query: '(max-height: 600px)' })

    

    const [homeRef, homeInView] = useInView({ threshold: 0 });
    const [partnersRef, partnersInView] = useInView({
        threshold:  isMobile ? 0.15 : isTablet ? 0.3 :   0.2,
    });

    
    const [footerRef, footerInView] = useInView({ threshold: 0.1 });

    const [navPartnersColor, NavPartnersColorView] = useInView({ threshold: isMobile ? 0.1:  0.2 });
    const [navAboutColor, NavAboutColorView] = useInView({ threshold: 0.2 }); 
    const [navScheduleColor, NavScheduleColorView] = useInView({ threshold: 0.2 });
    const [navCarouselColor, NavCarouselColorView] = useInView({ threshold: 0.2 });
    const [navDefaultColor, NavDefaultColorView] = useInView({ threshold: 0.4 });

    const setRefs = (element) => {
        partnersRef(element);
        navPartnersColor(element);
      };

    
    
    return (
        <div className={openedMenu ? 'non-scrollable-landing-page' : 'landing-page'}>
            
            <section id="landing-home" className="home-section" ref ={homeRef}>
                <HomeSection currentLanguage={currentLanguage} setCurrentLanguage={setCurrentLanguage} />
            </section>
            <div className="landing-sections">
                <section className={openedMenu ? 'mobile-navbar' : 'landing-navbar'}><Navbar openedMenu={openedMenu} toggleMenu={toggleMenu} isMobile={navbarIsMobile} currentLanguage={currentLanguage} NavPartnersColorView={NavPartnersColorView} NavAboutColorView={NavAboutColorView} NavScheduleColorView={NavScheduleColorView}  NavCarouselColorView={NavCarouselColorView} NavDefaultColorView={NavDefaultColorView} footerInView={footerInView}/></section>

            <section id="landing-about" ref={navAboutColor}>
               <AboutSection currentLanguage={currentLanguage} navDefaultColor={navDefaultColor}/>
            </section>
            <SectionChanger currentLanguage={currentLanguage} text={currentLanguage==="PT" ? "Quando?" : "When?"} color="#EB6F29" />
            <section id="landing-schedule" ref={navScheduleColor}>
                <ScheduleSection currentLanguage={currentLanguage}/>
            </section>
            <SectionChanger currentLanguage={currentLanguage} text={currentLanguage==="PT" ? "Descobre mais": "Find out more"} color="#EF80A2"/>
            <section id="landing-carousel" className="carousel-section" ref={navCarouselColor}>
                <CarouselSection currentLanguage={currentLanguage} />
            </section>
            <SectionChanger currentLanguage={currentLanguage} text={currentLanguage==="PT" ? "Os nossos parceiros de 2024" : "Our 2024 partners"} color="#4BB274"  partnersInView={partnersInView} contactProp={true} footerInView={footerInView} />

            <section id ="landing-partners" className="partners-section" ref={setRefs} >
                <PartnersSection currentLanguage={currentLanguage} />
            </section>
            <section className="landing-footer" ref={footerRef}>
            <Footer currentLanguage={currentLanguage} />
            </section>
            </div>
        </div>
    )
    }


export default LandingPage