
import './PartnersSection.css'
import assets from "../../assets/index"

const PartnersSection = (props) => {

    const partnersData = [
        {
          level: 'Powered By',
          partners: [assets.uc_logo, assets.cmc_logo, assets.first_foundation_logo],
        },
        {
          level: 'Gold',
          partners: [assets.redlight_logo, assets.void_logo, assets.singlestore_logo ],
        },
        {
            level: 'Silver',
            partners: [assets.acc_logo, assets.goodbarber_logo],
        },
        {
            level: 'Bronze',
            partners: [assets.critical_logo, assets.rumos_logo, assets.smartex_logo],
          },
        // Add more levels and partners as needed
      ];

    const partnersText = {
        "PT": {
            "apoios": "Apoios",
            "institucional": "Institucional",
            "producao": "Produção",
            "plataforma": "Plataforma",
            "outros": "Outros",
            "financed": "Financiado por"
        },
        "EN": {
            "apoios": "Support",
            "institucional": "Institutional",
            "producao": "Production",
            "plataforma": "Platform",
            "outros": "Others",
            "financed": "Financed by"
        }
    }
    

    return (
        <div className="partners-section">
            <div className="partners-container">
                {partnersData.map((group, index) => (
                <div key={index}>
                    <div className={`partners-header ${group.level === "Powered By" ? "powered-header" : " "}`}>
                        
                        {!(group.level === "Powered By") ? (<span className="partners-separator"></span> ) : null}
                        <h4 className="caps-text">{group.level}</h4>
                        {!(group.level === "Powered By") ? (<span className="partners-separator"></span> ) : null}
                        
                    </div>
                    <div className="partners-mapper">
                        {group.partners.map((partner, i) => (
                            <div key={i}>
                                <img src={partner}></img>
                            </div>
                        ))}
                    </div>
                </div>
                ))}
                <div className="apoios-container">
                    <div className="partners-header">
                        <span className="partners-separator"></span>
                        <h4 className="caps-text">{partnersText[props.currentLanguage].apoios}</h4>
                        <span className="partners-separator"></span>
                        
                    </div>
                    <div className="apoios-mapper">
                        <div>
                            <div>
                                <h6>{partnersText[props.currentLanguage].institucional}</h6>
                                <img src={assets.fctuc_logo}></img>
                            </div>
                            <div>
                                <h6>{partnersText[props.currentLanguage].producao}</h6>
                                <img src={assets.zona_pro_logo}></img>
                            </div>
                            <div>
                                <h6>{partnersText[props.currentLanguage].plataforma}</h6>
                                <img src={assets.taikai_logo}></img>
                            </div>
                        </div>
                        <div>
                            <h6>{partnersText[props.currentLanguage].financed}</h6>
                            <div className="other-partners-last">
                                <div>
                                    <img src={assets.prr_logo} alt="Prr logo"></img>
                                </div>
                                <div>
                                    <img src={assets.pt_logo} alt="Portugal logo"></img>
                                </div>
                                <div>
                                    <img src={assets.eu_logo} alt="Eu logo"></img>
                                </div>
                                <div>
                                    <img src={assets.flag_logo} alt="flag logo" style={{width:"50%"}}></img>
                                </div>
                                <div>
                                    <img src={assets.framedrop_logo} alt="framedrop logo" style={{width:"50%"}}></img>
                                </div>
                                <div>
                                    <img src={assets.outsystems_logo} alt="outsystems logo" style={{width:"50%"}}></img>
                                </div>
                                
                            </div>
                        </div>
                        <div>
                            <h6>{partnersText[props.currentLanguage].outros}</h6>
                            <div className="other-partners">
                                <div>
                                    <img src={assets.multicoffee_logo} alt="Multicoffee logo" style={{width:"25%"}}></img>
                                </div>
                                <div>
                                    <img src={assets.paladin_logo} alt="Paladin logo"  style={{width:"25%"}}></img>
                                </div>
                                <div>
                                    <img src={assets.red_bull_logo} alt="Red Bull logo"  style={{width:"25%"}}></img>
                                </div>
                                <div>
                                    <img src={assets.frusel_logo} alt="frusel logo"  style={{width:"25%"}}></img>
                                </div>

                            </div>
                        </div>
                        
                    </div>

                </div>
            </div>
        </div>


    )
}

export default PartnersSection