
import ReactLoading from "react-loading";
import "./ConcludedRegistryPage.css"
import assets from "../../assets/index"
import { useMediaQuery } from 'react-responsive';

const ConcludedRegistryPage = (props) => {

    const returnLadingPage = () => {
        window.location.href = "/"
    }

    const isMobile = useMediaQuery({ query: '(max-width: 700px)' })

    const text = {
        "PT": {
            "loading": "O teu bilhete para a inovação está quase pronto.",
            "confirm": "Confirma o teu e-mail e celebra connosco o sucesso da tua inscrição!",
            "contact": "Em caso de dúvida não hesites em contactar-nos: ",
            "contact_email": "example@example.com", // Provide a non-empty string value
            "ready1": "Estamos prontos para te receber no palco",
            "ready2": "do maior Hackathon da zona centro!",
            "ready1mobile": "Estamos prontos para te receber ",
            "ready2mobile": "no palco do maior Hackathon ",
            "ready3mobile": "da zona centro!",
            "goback": "Voltar à página inicial"
        },
        "EN": {
            "loading": "Your ticket to innovation is almost ready.",
            "confirm": "Confirm your email and celebrate with us the success of your registration!",
            "contact": "In case of doubt don't hesitate to contact us: ",
            "contact_email": "example@example.com", // Provide a non-empty string value
            "ready1": "We are ready to welcome you to the stage",
            "ready2": "of the biggest Hackathon in the central zone!",
            "ready1mobile": "We are ready to welcome you",
            "ready2mobile": "to the stage of the biggest",
            "ready3mobile": "Hackathon in the central zone!",
            "goback": "Go back to the home page"
        }
    };

    

    return (
        <div className="concluded-registry-page">
                <img className="concluded-registry-page-right-img" src={assets.loading_page_image_1}></img>
                <img className="concluded-registry-page-left-img" src={assets.loading_page_image_2}></img>
                <div className="concluded-registry-container">

                    { props.loading ? (
                        <ReactLoading type='spin' color='#E3314E' />
                    ) : (
                        <svg xmlns="http://www.w3.org/2000/svg" width="52" height="52" viewBox="0 0 52 52" fill="none">
                            <circle cx="26" cy="26" r="26" fill="#E3314E"/>
                            <path d="M42.5248 17.6858L23.6715 38.253L13 26.6114L17.0208 22.9256L23.6715 30.1809L38.504 14L42.5248 17.6858Z" fill="#F5F5FA"/>
                        </svg>

                    )}
                    <h2>Check, Check!</h2>
                    { props.loading ? (
                        <p >{text[props.currentLanguage].loading}</p>
                        ) : (
                        <div className="concluded-registry-info">
                            <p>{text[props.currentLanguage].confirm} </p>
                            

                            <p  >{text[props.currentLanguage].contact} <span className="bold-text font-poppins">participantes@shiftappens.com</span></p>
                            <div >
                            {!isMobile
                            ?
                                <span>
                                        {text[props.currentLanguage].ready1}<br></br> {text[props.currentLanguage].ready2}
                                </span>   
                                : <span> {text[props.currentLanguage].ready1mobile}<br></br> {text[props.currentLanguage].ready2mobile}<br></br> {text[props.currentLanguage].ready3mobile}</span>
        }                           
                            </div>
                            <button onClick={returnLadingPage}>{text[props.currentLanguage].goback}</button>
                        </div>
                    )}
                    
                </div>
        </div>

    )

}

export default ConcludedRegistryPage