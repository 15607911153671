import React, { useState } from 'react';
import { FaChevronDown, FaChevronUp } from 'react-icons/fa';
import assets from '../../assets/index';
import './Cards.css';



const AboutPageCards = (props) => {
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);

    const toggleDropdown = () => {
        setIsDropdownOpen(!isDropdownOpen);
    };

    return (
        <div className='about-card-box'>
            <img className="about-card-box-icon" src={props.image} alt={props.title}></img>
            {props.index === 1 && !props.isMobile ? (
                <img className="about-page-floating-icon" src={assets.about_star_icon} alt="Floating Icon"></img> 
            ) : null} 
            {!props.isMobile ? (
                <div className="about-card-items">
                    <div className="about-card-text">
                        <p>{props.text1Intro} <span className="bold-text">{props.text1Bold}</span> {props.text}</p>
                        <p>{props.text2}</p>
                    </div>
                    <div className="about-card-header-container">
                        <h2>{props.title}</h2>
                    </div>
                </div>
            ) : (
                
                <div className="about-card-dropdown">
                    <div onClick={toggleDropdown}>
                        {!isDropdownOpen ? (
                            <div className="about-card-dropdown-header">
                                <h2>{props.miniTitle}</h2>
                                <FaChevronDown style={{color: "#EF80A2", width:"5%", height:"100%", cursor:"pointer"}} /> 
                            </div>
                        ) : null}
                        
                    </div>
                    {isDropdownOpen && (
                        <div className="about-card-dropdown-content">
                            <div className="about-card-text">
                                <p>{props.text1Intro} <span className="bold-text">{props.text1Bold}</span> {props.text}</p>
                                <p>{props.text2}</p>
                            </div>
                            <div className="about-card-dropdown-header">
                                <h2>{props.title}</h2>
                                <FaChevronUp style={{color: "#EF80A2", width:"5%", height:"100%", cursor:"pointer"}} onClick={toggleDropdown}/> 
                            </div>
                        </div>
                    )}
                    
                </div>
            )}
        </div>
    );
};


const FAQPageCards = (props) => {

    const [isDropdownOpen, setIsDropdownOpen] = useState(false);

    const toggleDropdown = () => {
        setIsDropdownOpen(!isDropdownOpen);
    }

    const item = props.item;

    
    
    return (
        <div className={`faq-page-card ${isDropdownOpen ? 'opened-dropdown-border' : ''}`}>
    
            <div className={`faq-card-dropdown-header ${isDropdownOpen ? 'opened-dropdown-header' : ''}`} onClick={toggleDropdown} >
            <h2 style={{ color: !isDropdownOpen ? "#4A73B8" : "#EB6F29" }}>{props.question}</h2>

                {isDropdownOpen 
                    ? <FaChevronUp style={{color: !isDropdownOpen ? "#4A73B8" : "#EB6F29", width:"5%", height:"100%", cursor:"pointer"}} onClick={toggleDropdown}/> 
                    : <FaChevronDown style={{color: !isDropdownOpen ? "#4A73B8" : "#EB6F29", width:"5%", height:"100%", cursor:"pointer"}} />
                }
                
            </div>
            
            {isDropdownOpen && (
                <div className="faq-card-dropdown-content">
                    {item.answer1 && <p style={{ color: !isDropdownOpen ? "#4A73B8" : "#EB6F29" }}>{item.answer1}</p>}
                    {item.answer2 && <p style={{ color: !isDropdownOpen ? "#4A73B8" : "#EB6F29" }}>{item.answer2}</p>}
                    {item.answerpoint1 && <p style={{ color: !isDropdownOpen ? "#4A73B8" : "#EB6F29" }}>- {item.answerpoint1}</p>} 
                    {item.answerpoint2 && <p style={{ color: !isDropdownOpen ? "#4A73B8" : "#EB6F29" }}>- {item.answerpoint2}</p>}
                    {item.answerpoint3 && <p style={{ color: !isDropdownOpen ? "#4A73B8" : "#EB6F29" }}>- {item.answerpoint3}</p>}            
                </div>
            )}
            
        </div>
    
    ) 
}

export { AboutPageCards, FAQPageCards };