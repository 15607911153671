import react, { useState } from 'react'
import "./LanguageChanger.css"

const LanguageChanger = (props) => {

    

    return (
        <div className= {` language-changer-container ${props.register ? 'yellow' : 'red'}`}>
            <span onClick={()=> props.setCurrentLanguage(props.currentLanguage === "PT" ? "EN" : "PT")} className={props.currentLanguage === "PT" ? 'selected-language' : 'not-selected'}>PT</span>
            <span onClick={()=> props.setCurrentLanguage(props.currentLanguage === "EN" ? "PT" : "EN")} className={props.currentLanguage === "EN" ? 'selected-language' : 'not-selected'}>EN</span>
        </div>
    )
}

export default LanguageChanger